import {BehaviorSubject, Observable} from 'rxjs';
import {Logger} from '@nirby/logger';
import {map} from 'rxjs/operators';

export interface MoveOperation<T> {
    item: T;
    result: T[];
    from: number;
    to: number;
}

/**
 * A wrapper to a {@link BehaviorSubject} with an array, to easily apply array operations.
 */
export class ArraySubject<T> {
    protected readonly subject: BehaviorSubject<T[]>;

    /**
     * The length of the array.
     */
    get length(): number {
        return this.subject.value.length;
    }

    /**
     * Constructor.
     * @param initialValue The initial value of the array.
     */
    constructor(initialValue?: T[]) {
        this.subject = new BehaviorSubject<T[]>(initialValue ?? []);
    }

    /**
     * Returns an observable of the array.
     *
     * @returns - An observable of the array.
     */
    asObservable(): Observable<T[]> {
        return this.subject.pipe(
            map(value => [...value]),
        );
    }

    /**
     * A copy of the array.
     */
    get value(): T[] {
        return [...this.subject.value];
    }

    /**
     * Sets a new value to the array.
     * @param value The new value of the array.
     *
     * @returns -
     */
    next(value: T[]): void {
        return this.subject.next(value);
    }

    /**
     * Pushes an item to the end of the array.
     * @param value The item to push.
     */
    push(...value: T[]): void {
        const original = this.subject.value;
        original.push(...value);
        this.next(original);
    }

    /**
     * Removes the last element of the array and returns it.
     *
     * @returns - The last element of the array.
     */
    pop(): T | undefined {
        const original = this.subject.value;
        const value = original.pop();
        this.next(original);
        return value;
    }

    /**
     * Checks if an index is inside the array.
     * @param index The index to check.
     * @private
     *
     * @returns - True if the index is valid, false otherwise.
     */
    private isIndexValid(index: number): boolean {
        return index >= 0 && index < this.subject.value.length;
    }

    /**
     * Finds an item in the array and moves it to the given index.
     *
     * @param originalIndex The index of the item to move.
     * @param destinyIndex The index to move the item to.
     *
     * @returns The item that was moved.
     */
    move(
        originalIndex: number,
        destinyIndex: number,
    ): MoveOperation<T> | null {
        const original = this.subject.value;
        if (originalIndex === destinyIndex) {
            return null;
        }
        if (!this.isIndexValid(originalIndex) || !this.isIndexValid(destinyIndex)) {
            Logger.warnStyled(
                'BLOCK-EDITOR-STORE:MOVE',
                `Could not find item to move to index ${destinyIndex}.`,
            );
            return null;
        }
        const copy = [...original];

        // remove item from original array
        const value = copy.splice(originalIndex, 1)[0];

        // insert item at new index
        copy.splice(destinyIndex, 0, value);

        // emit updated array
        this.next(copy);

        // using Konva 8.2.3 the only affected item is the one that was moved, as moving this one will move the rest
        // const start = Math.min(originalIndex, destinyIndex);
        // const end = Math.max(originalIndex, destinyIndex);
        return {
            // the affected item are all the item that were moved
            item: value,
            result: [...copy],
            from: originalIndex,
            to: destinyIndex,
        };
    }

    /**
     * Finds an element in the array.
     * @param findFn The function to find the item.
     *
     * @returns - The item that was found.
     */
    find(findFn: (item: T) => boolean): T | undefined {
        const original = [...this.subject.value];
        const item = original.find(findFn);
        if (item === undefined) {
            return undefined;
        }
        return item;
    }

    /**
     * Finds an item in the array and removes it.
     * @param findFn The function to find the item.
     *
     * @returns - The item that was removed.
     */
    findAndRemove(findFn: (item: T) => boolean): T | undefined {
        const copy = [...this.subject.value];
        const item = copy.find(findFn);
        if (item === undefined) {
            return undefined;
        }
        const index = copy.indexOf(item);
        copy.splice(index, 1);
        this.next(copy);
        return item;
    }

    /**
     * Removes an item from the array.
     * @param target The item to remove.
     */
    remove(target: T): void {
        const copy = [...this.subject.value];
        const index = copy.indexOf(target);
        copy.splice(index, 1);
        this.next(copy);
    }
}

/**
 * A wrapper to a {@link BehaviorSubject} with an array, that indexes the array using the given hash function.
 */
export class IndexedArraySubject<TItem> extends ArraySubject<TItem> {
    private index: Map<string, number> = new Map();

    /**
     * Constructor.
     * @param hashFn The function to hash the item.
     * @param initialValue The initial value of the array.
     */
    constructor(
        private readonly hashFn: (value: TItem) => string,
        initialValue?: TItem[],
    ) {
        super(initialValue);
        this.index = IndexedArraySubject.buildIndexFromValues<TItem>(initialValue ?? [], hashFn);
    }

    /**
     * Builds a map of indexes from the given array.
     * @param values The array to build the index from.
     * @param hashFn The function to hash the item.
     * @private
     *
     * @returns - The map of indexes.
     */
    private static buildIndexFromValues<TItem>(
        values: TItem[],
        hashFn: (value: TItem) => string,
    ): Map<string, number> {
        return new Map(
            values.map((item, index) => [hashFn(item), index]),
        );
    }

    /**
     * Pushes an item to the end of the array.
     * @param value The item to push.
     */
    override push(...value: TItem[]) {
        let expectedIndex = this.length;

        const newValues: TItem[] = [];
        for (const item of value) {
            const hash = this.hashFn(item);
            const oldValue = this.index.get(hash);
            if (oldValue !== undefined) {
                Logger.warn(`Adding an item whose hash (${hash}) already exists in the array. Skipping.`);
                continue;
            }
            newValues.push(item);
            this.index.set(hash, expectedIndex++);
        }
        super.push(...newValues);
    }

    /**
     * Removes the last element of the array and returns it.
     *
     * @returns - The last element of the array.
     */
    override pop(): TItem | undefined {
        const item = super.pop();
        if (item === undefined) {
            return undefined;
        }
        this.index.delete(this.hashFn(item));
        return item;
    }

    /**
     * Gets the index of the given item.
     * @param hash The item to get the index of.
     *
     * @returns - The index of the item.
     */
    indexOfHash(hash: string): number {
        return this.index.get(hash) ?? -1;
    }

    /**
     * Gets the index of the given item.
     * @param item The item to get the index of.
     * @param searchByHash Whether to use the hash of the item to get the index. It's much faster, but might return an
     * incorrect index if the given item is not in the array and the array contains an item with the same hash.
     *
     * @returns - The index of the item.
     */
    indexOf(item: TItem, searchByHash = true): number {
        if (searchByHash) {
            return this.indexOfHash(this.hashFn(item));
        }
        return this.subject.value.indexOf(item);
    }

    /**
     * Sets a new array.
     * @param value The new array.
     */
    override next(value: TItem[]): void {
        this.index = IndexedArraySubject.buildIndexFromValues<TItem>(value, this.hashFn);
        super.next(value);
    }

    /**
     * Moves an item to the given index.
     * @param originalIndex The index of the item to move.
     * @param destinyIndex The index to move the item to.
     *
     * @returns The description of the move operation.
     */
    override move(originalIndex: number, destinyIndex: number): MoveOperation<TItem> | null {
        const operation = super.move(originalIndex, destinyIndex);
        if (operation === null) {
            return null;
        }

        /*
         how affected item are identified:

         0,  1,  2,  3,  4,  5,  6: (source)

         0, *2, *3, *4, *1,  5,  6: (1 -> 4)

         0, *5, *1, *2, *3, *4,  6: (5 -> 1)

         * = affected item
         */

        const start = Math.min(originalIndex, destinyIndex);
        const end = Math.max(originalIndex, destinyIndex);
        for (let i = start; i <= end; i++) {
            const affectedItem = operation.result[i];
            const affectedItemHash = this.hashFn(affectedItem);
            this.index.set(affectedItemHash, i);
        }
        return operation;
    }
}
