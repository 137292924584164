// noinspection JSUnusedGlobalSymbols

import {HRefTarget, TriggerDomEvents} from '@nirby/models/actions';
import {Contact} from '@nirby/runtimes/context';
import {Logger} from '@nirby/logger';
import {NirbyVariableNullable} from '@nirby/runtimes/state';

type NirbyMessage<Type extends string, Data extends object | null = null> = {
    type: Type;
    data: Data;
};

export type NirbyPopMessage =
    | NirbyMessage<
    'init',
    {
        path: string;
        apiKey?: string;
        contact: string | null;
        impressed: boolean | null;
        persistent?: Partial<Record<string, NirbyVariableNullable>>;
    }
>
    | NirbyMessage<
    'store-persistent',
    {
        data: Partial<Record<string, NirbyVariableNullable>>;
    }
>
    | NirbyMessage<
    'resize',
    {
        right: string;
        bottom: string;
        width: string;
        height: string;
    }
>
    | NirbyMessage<'closed'>
    | NirbyMessage<
    'trigger-dom',
    {
        selector: string;
        event: string;
    }
>
    | NirbyMessage<
    'store-contact',
    {
        contact: string;
    }
>
    | NirbyMessage<'mark-as-impressed'>
    | NirbyMessage<
    'go-to-url',
    {
        url: string;
        target: HRefTarget;
    }
>
    | NirbyMessage<
    'answers',
    {
        data: Record<string, NirbyVariableNullable>;
    }
>;

type PickParentMessageFrom<M, K extends NirbyPopMessage['type']> = M extends {
        type: K;
    }
    ? M
    : never;
export type PickParentMessage<K extends NirbyPopMessage['type']> =
    PickParentMessageFrom<NirbyPopMessage, K>;

/**
 * Handles communication between the parent window and this window.
 */
export class ParentWindow {
    /**
     * Posts a message to the parent window.
     * @param event The message to post.
     * @private
     */
    private static postMessage(event: NirbyPopMessage): void {
        const parent = window.parent;
        if (!parent) {
            Logger.logStyled(
                'IFRAME',
                `Trying to call event on parent, but no parent was found: ${event}`
            );
            return;
        }
        Logger.logStyled('IFRAME:MESSAGE', event.type, event);
        parent.postMessage(event, '*');
    }

    /**
     * Sends a message to resize the container
     * @param right The right offset.
     * @param bottom The bottom offset.
     * @param width The width.
     * @param height The height.
     */
    public resizeContainer(
        right: string,
        bottom: string,
        width: string,
        height: string
    ): void {
        ParentWindow.postMessage({
            type: 'resize',
            data: {
                width,
                height,
                right,
                bottom,
            },
        });
    }

    /**
     * Sends a message to close the container.
     */
    public destroyContainer(): void {
        ParentWindow.postMessage({
            type: 'closed',
            data: null,
        });
    }

    /**
     * Checks whether there's a parent window.
     */
    public get exists(): boolean {
        return window.location !== window.parent.location;
    }

    /**
     * Sends a message to the parent window to trigger a DOM event.
     * @param selector The selector to trigger the event on.
     * @param event The event to trigger.
     */
    public triggerDom(selector: string, event: TriggerDomEvents): void {
        ParentWindow.postMessage({
            type: 'trigger-dom',
            data: {
                selector,
                event,
            },
        });
    }

    /**
     * Store contact on parent window
     *
     * Contact is stored on the parent window to avoid other Nirby workspaces using this iframe to access another contact identity
     *
     * @param contact Contact to be saved
     */
    setStoredContact(contact: Contact): void {
        if (!contact.token) {
            Logger.logStyled(
                'CONTACT:SET',
                'Could not update stored contact, contact is not authenticated',
                contact.id,
                contact.attributes
            );
            return;
        }
        ParentWindow.postMessage({
            type: 'store-contact',
            data: {
                contact: contact.token,
            },
        });
    }

    /**
     * @param data Data to be persisted
     */
    setPersistentData(
        data: Partial<Record<string, NirbyVariableNullable>>
    ): void {
        ParentWindow.postMessage({
            type: 'store-persistent',
            data: {data},
        });
    }

    /**
     * Sends a message to mark the container as impressed.
     */
    markAsImpressed(): void {
        ParentWindow.postMessage({
            type: 'mark-as-impressed',
            data: null,
        });
    }

    /**
     * Sends a message to the parent window to navigate to a URL.
     * @param url The URL to navigate to.
     * @param target The target to open the URL in.
     */
    goToUrl(url: string, target: HRefTarget = '_blank'): void {
        window.open(url, target);
    }
}
