import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {PublishedService} from '../../services/published.service';
import {ActivatedRoute} from '@angular/router';
import {environment} from '../../../environments/environment';
import {Subscription} from 'rxjs';
import {FullProductId} from '@nirby/analytics-typings';
import {CompiledPop} from '@nirby/models/pop';
import {Logger} from '@nirby/logger';
import {AnalyticsService, ContactsService, NgTranslator} from '@nirby/player';
import {Contact, NirbyContext} from '@nirby/runtimes/context';
import {NirbyPopMessage, ParentWindow} from '@nirby/js-utils/embed';
import {WithId} from '@nirby/store/models';

@Component({
    selector: 'nirby-player',
    templateUrl: './player.component.html',
    styleUrls: ['./player.component.scss'],
})
/**
 * The Pop Player component.
 */
export class PlayerComponent implements OnInit, OnDestroy {
    pop: WithId<CompiledPop> | null = null;
    isPlayerActive = false;
    context = new NirbyContext(this.translator);

    subscription = new Subscription();

    /**
     * Constructor.
     * @param route The activated route.
     * @param parent The parent service.
     * @param service The published service.
     * @param analytics The analytics service.
     * @param contacts The contacts service.
     * @param translator The translator service.
     */
    constructor(
        private route: ActivatedRoute,
        private parent: ParentWindow,
        private service: PublishedService,
        private analytics: AnalyticsService,
        private contacts: ContactsService,
        private translator: NgTranslator,
    ) {
    }

    /**
     * Load the Pop associated with the given id at this path
     * @param path Path
     */
    async load(path: string): Promise<void> {
        const workspaceId = this.route.snapshot.paramMap.get('workspaceId');
        if (!workspaceId) {
            Logger.warnStyled('POP', 'Workspace ID not found');
            return;
        }

        this.pop = await this.service.getPopForPath(workspaceId, path);

        // if no campaign was found, close the pop container to improve client's performance
        if (!this.pop) {
            Logger.warnStyled('POP', 'No pop was found. Closing container');
            this.parent.destroyContainer();
            return;
        }

        // use unauthenticated contact if no contact was defined in the parent

        // initialize
        const contact = Contact.loadFromStorage(workspaceId) ?? Contact.unauthenticated();
        const productId = new FullProductId(
            workspaceId,
            this.pop._docId,
            'pop',
            'legacy',
        );
        await this.context.init(
            contact,
            productId,
            this.pop.variableDeclaration ?? {},
        );

        this.context.impress();
    }

    /**
     * Handles messages from the parent container
     * @param event Event
     */
    @HostListener('window:message', ['$event'])
    async onParentMessage(event: MessageEvent): Promise<void> {
        const message = event.data as NirbyPopMessage;
        switch (message.type) {
            case 'init': {
                const path: string = message.data.path;
                await this.load(path);
                break;
            }
        }
    }

    /**
     * If not in productions and no parent container is defined, load a mock Pop.
     */
    async ngOnInit(): Promise<void> {
        // Init immediately on development without the need of an i-frame
        if (!environment.production && !this.parent.exists) {
            await this.load('/' + (this.route.snapshot.queryParams['path'] ?? ''));
        }
    }

    /**
     * Unsubscribe from all subscriptions.
     */
    ngOnDestroy(): void {
        this.subscription.unsubscribe();
        this.subscription = new Subscription();
    }
}
