// noinspection JSUnusedGlobalSymbols

import {BehaviorSubject, combineLatest, filter, merge, Observable, of, Subject} from 'rxjs';
import {debounceTime, map, shareReplay, switchMap} from 'rxjs/operators';
import {Logger} from '@nirby/logger';
import {NirbyBoardItemStandard, NirbyBoardStateNode} from '../art-board-item-factory';
import {IndexedArraySubject, MapSubject, MoveOperation} from '../subjects';
import {QuickUpdater, TreeChange, TreeChangeCRUD, TreeChangeHistory, TreeItem} from './history/tree-change-history';
import {ArtBoardItemChange} from '../art-board-item';
import {ReactiveKeyValueStorage, StorageChange, StorageChangeUpdate} from './storage';

/**
 * Controller for
 */
class BlockSorter<TMeta> {
    /**
     * Constructor.
     * @param array The subject for an array of store item
     */
    constructor(
        private readonly array: IndexedArraySubject<SingleLevelBoardStore<TMeta>>,
    ) {
    }

    /**
     * Bring a block to the front
     * @param blockId Block ID
     */
    bringToFront(blockId: string): void {
        this.array.move(
            this.array.indexOfHash(blockId),
            this.array.length - 1,
        );
    }

    /**
     * Bring a block forward
     * @param blockId Block ID
     * @param steps Steps
     */
    bringForward(blockId: string, steps = 1): void {
        const currentIndex = this.array.indexOfHash(blockId);
        this.array.move(
            this.array.indexOfHash(blockId),
            currentIndex + steps,
        );
    }

    /**
     * Bring a block backward
     * @param blockId Block ID
     * @param steps Steps
     */
    bringBackward(blockId: string, steps = 1): void {
        this.bringForward(blockId, -steps);
    }

    /**
     * Bring a block to the back
     * @param blockId Block ID
     */
    bringToBack(blockId: string): void {
        this.array.move(
            this.array.indexOfHash(blockId),
            0,
        );
    }
}

export interface ReadStore<T> {
    allNodes(): Iterable<T>;

    findById(id: string): T | null;
}

/**
 * Stores the current card
 */
export class SingleLevelBoardStore<TMeta, TItem extends NirbyBoardItemStandard<TMeta> = NirbyBoardItemStandard<TMeta>>
    implements ReadStore<SingleLevelBoardStore<TMeta>> {
    public readonly id: string;
    public readonly type: TItem['type'];
    private readonly propertiesSubject: BehaviorSubject<TItem['properties']>;
    private readonly childrenSubject: IndexedArraySubject<SingleLevelBoardStore<TMeta>>;
    private readonly metaSubject: BehaviorSubject<TMeta | null>;

    public readonly value$: Observable<NirbyBoardStateNode<TMeta>>;
    public readonly sorter: BlockSorter<TMeta>;

    /**
     * The index of this item in the parent. -1 if no parent
     */
    get index(): number {
        return this.parent ? this.parent.childrenSubject.indexOfHash(this.id) : -1;
    }

    /**
     * Constructor
     * @param initialState The initial state
     * @param parent Parent store
     */
    private constructor(
        initialState: NirbyBoardStateNode<TMeta>,
        public readonly parent?: SingleLevelBoardStore<TMeta>,
    ) {
        this.id = initialState.id;
        this.type = initialState.type;
        this.propertiesSubject = new BehaviorSubject<TItem['properties']>(
            initialState.properties,
        );
        this.childrenSubject = new IndexedArraySubject<SingleLevelBoardStore<TMeta>>(
            item => item.id,
            initialState.children.map(
                (child) => new SingleLevelBoardStore<TMeta>(child, this),
            ),
        );
        this.metaSubject = new BehaviorSubject<TMeta | null>(initialState.meta);
        this.sorter = new BlockSorter(this.childrenSubject);
        this.value$ = combineLatest([
            this.propertiesSubject.asObservable(),
            this.children$,
        ]).pipe(
            debounceTime(100),
            switchMap(([properties, children]) => this.metaSubject.pipe(
                map(meta =>
                    ({
                        properties,
                        children,
                        meta,
                    })),
            )),
            map(({properties, children, meta}): NirbyBoardStateNode<TMeta> => ({
                id: this.id,
                type: this.type,
                properties,
                children,
                meta,
            })),
            shareReplay({refCount: true, bufferSize: 1}),
        );
    }

    /**
     * Observable of the children item
     */
    get children$(): Observable<NirbyBoardStateNode<TMeta>[]> {
        return this.childrenSubject.asObservable().pipe(
            switchMap((childrenStores): Observable<NirbyBoardStateNode<TMeta>[]> => {
                const childrenValues$ = Array.from(childrenStores.values()).map(
                    (child) => child.value$,
                );
                return childrenValues$.length > 0
                    ? combineLatest(childrenValues$)
                    : of([] as NirbyBoardStateNode<TMeta>[]);
            }),
        );
    }

    /**
     * Builds a new store for a level in the art-board tree and its children
     * @param state The state from where to build
     * @param parent The parent store
     *
     * @returns - The new store
     */
    public static build<TMeta>(
        state: NirbyBoardStateNode<TMeta>,
        parent?: SingleLevelBoardStore<TMeta>,
    ): SingleLevelBoardStore<TMeta> {
        return new SingleLevelBoardStore<TMeta>(state, parent);
    }

    /**
     * Get the current card
     */
    public get value(): NirbyBoardStateNode<TMeta, TItem> {
        const properties: NirbyBoardStateNode<TMeta, TItem>['properties'] = this.properties;
        return {
            id: this.id,
            type: this.type,
            properties,
            children: this.childrenSubject.value.map((child) => child.value),
            meta: this.metaSubject.value,
        };
    }

    /**
     * Get the properties of this item
     */
    public get properties(): NirbyBoardStateNode<TMeta, TItem>['properties'] {
        return {...this.propertiesSubject.value};
    }

    /**
     * The metadata of this item
     */
    public get meta(): TMeta | null {
        return this.metaSubject.value;
    }

    /**
     * Get all stores in the tree
     *
     * @returns - The stores
     */
    public allNodes(): SingleLevelBoardStore<TMeta>[] {
        const grandChildren = this.childrenSubject.value
            .map((child) => child.allNodes())
            .reduce((acc, cur) => acc.concat(cur), []);
        return [
            this as unknown as SingleLevelBoardStore<TMeta>,
            ...grandChildren,
        ];
    }

    /**
     * Creates a block
     * @param state The state to use for creation
     * @param index The index to insert the block at
     *
     * @returns - The new store
     */
    public addChild(state: NirbyBoardStateNode<TMeta>, index: number | null): SingleLevelBoardStore<TMeta> | null {
        Logger.logStyled(
            'BLOCK-EDITOR-STORE',
            `Block ${state.id} was inserted at ${this.id}`,
        );
        const currentChildren: NirbyBoardStateNode<TMeta>[] = [...this.children.map(c => c.value)];
        if (index === null) {
            currentChildren.push(state);
        } else {
            currentChildren.splice(index, 0, state);
        }
        this.setChildren(currentChildren);
        return this.children.find((child) => child.id === state.id) ?? null;
    }

    /**
     * Number of children
     */
    public get childrenCount(): number {
        return this.childrenSubject.length;
    }

    /**
     * Removes a block
     * @param itemId The block to remove
     *
     * @returns - The removed store
     */
    public remove(itemId: string): SingleLevelBoardStore<TMeta> | null {
        const removed = this.childrenSubject.findAndRemove(
            (item) => item.id === itemId,
        );
        if (removed) {
            Logger.logStyled(
                'BLOCK-EDITOR-STORE:REMOVE-DIRECT',
                `Block "${itemId}" was deleted from store at "${this.id}"`,
            );
            return removed;
        }
        return null;
    }

    /**
     * Finds a block by ID
     * @param id Block ID
     *
     * @returns Block or null
     */
    public findById(id: string): SingleLevelBoardStore<TMeta> | null {
        return this.childrenSubject.find((item) => item.id === id) ?? null;
    }

    /**
     * Finds the index of a block
     * @param itemId Block ID
     *
     * @returns Block index or -1
     */
    public indexOf(itemId: string): number {
        return this.childrenSubject.indexOfHash(itemId);
    }

    /**
     * Update the properties of this item
     * @param properties The new properties
     */
    update(properties: NirbyBoardStateNode<TMeta>['properties']): void {
        this.propertiesSubject.next(properties);
    }

    /**
     * Get the children item stores
     *
     * @returns - The children
     */
    public get children(): SingleLevelBoardStore<TMeta>[] {
        return Array.from(this.childrenSubject.value);
    }

    /**
     * Set the children item stores
     * @param newChildren The new children
     */
    setChildren(newChildren: NirbyBoardStateNode<TMeta>['children']): void {
        const currentChildren = new Map(
            this.children.map((child) => [child.id, child]),
        );
        const newChildrenStates = new Map(
            newChildren.map((child) => [child.id, child]),
        );

        const toAdd = new Map<string, SingleLevelBoardStore<TMeta>>();

        let newChild: NirbyBoardStateNode<TMeta>;
        for (newChild of newChildren) {
            const existingChild = currentChildren.get(newChild.id);
            if (existingChild) {
                // update
                existingChild.update(newChild.properties);
                existingChild.setChildren(newChild.children);
                newChildrenStates.set(newChild.id, existingChild);
            } else {
                // create
                const child = SingleLevelBoardStore.build(newChild, this);
                toAdd.set(child.id, child);
                Logger.logStyled(
                    'BOARD:STORE',
                    `Item ${newChild.id} was created`,
                );
            }
        }
        this.childrenSubject.next(
            newChildren
                .map((child) => currentChildren.get(child.id) ?? toAdd.get(child.id))
                .filter((s): s is SingleLevelBoardStore<TMeta> => {
                    if (s === undefined) {
                        Logger.warnStyled(
                            'BOARD:STORE',
                            `Item ${newChild.id} was not found`,
                        );
                    }
                    return s !== undefined;
                }),
        );
        currentChildren.forEach((child, id) => {
            if (!newChildrenStates.has(id)) {
                // delete
                this.remove(id);
            }
        });
    }

    /**
     * Moves a children item to a new position in the children array
     * @param id The ID of the item to move
     * @param destinyIndex The new position on the array
     *
     * @returns - The move operation description
     */
    moveToIndex(
        id: string,
        destinyIndex: number,
    ): MoveOperation<SingleLevelBoardStore<TMeta>> | null {
        return this.childrenSubject.move(
            this.childrenSubject.indexOfHash(id),
            destinyIndex,
        );
    }

    /**
     * Updates the metadata of this item
     * @param meta The new metadata
     */
    updateMeta(meta: TMeta | null): void {
        this.metaSubject.next(meta);
    }
}

/**
 * Store to manage an art-board item and all of its children on the tree
 */
export class MultiLevelBoardStore<TMeta> implements ReactiveKeyValueStorage<NirbyBoardStateNode<TMeta>> {
    private readonly controller: SingleLevelBoardStore<TMeta>;
    private readonly index: MapSubject<string, SingleLevelBoardStore<TMeta>>;

    private readonly metaChangesSubject = new Subject<StorageChangeUpdate<TMeta>>();

    /**
     * The IDs of item whose metadata has changed
     */
    get metaChanges$(): Observable<StorageChangeUpdate<TMeta>> {
        return this.metaChangesSubject.asObservable();
    }

    /**
     * Watches for changes on the store
     *
     * @returns - The changes
     */
    watchChanges(): Observable<StorageChange<NirbyBoardStateNode<TMeta>>> {
        return merge(
            this.changes$.pipe(
                map((change): StorageChange<NirbyBoardStateNode<TMeta>> => {
                    switch (change.type) {
                        case 'insert':
                            return {
                                type: 'insert',
                                id: change.id,
                                newValue: change.data,
                            };
                        case 'update':
                            return {
                                type: 'update',
                                id: change.id,
                                newValue: change.newValue,
                                previousValue: change.previousValue,
                            };
                        case 'remove':
                            return {
                                type: 'delete',
                                id: change.id,
                                previousValue: change.previousValue,
                            };
                    }
                }),
            ),
            this.metaChanges$.pipe(
                map((change): StorageChange<NirbyBoardStateNode<TMeta>> | null => {
                        const value = this.index.get(change.id)?.value;
                        if (value) {
                            return {
                                type: 'update',
                                id: change.id,
                                newValue: {
                                    ...value,
                                    meta: change.newValue,
                                },
                                previousValue: {
                                    ...value,
                                    meta: change.previousValue,
                                },
                            };
                        }
                        return null;
                    },
                ),
                filter((change): change is StorageChange<NirbyBoardItemStandard<TMeta>> => {
                    return change !== null;
                }),
            ),
        );
    }

    /**
     * Watch the IDs available on the tree
     */
    public get availableIds$(): Observable<string[]> {
        return this.index.keys$;
    }

    private readonly operations = {
        findById: (id: string): TreeItem<NirbyBoardStateNode<TMeta>> | null => {
            const item = this.index.get(id);
            if (!item) {
                return null;
            }
            const data = item.value;
            return {
                id,
                data,
                parentId: item.parent?.id ?? null,
                children: item.children.map((c) => ({
                    id: c.id,
                    data: c.value,
                    parentId: item.id,
                    children: [],
                })),
            };
        },
        insert: (
            id: string,
            item: NirbyBoardStateNode<TMeta>,
            parentId: string | null,
            index: number | null,
        ): void => {
            if (!parentId) {
                return;
            }
            const store = this.index.get(parentId);
            if (!store) {
                return;
            }

            const newStore = store.addChild({
                id,
                type: item.type,
                properties: item.properties,
                children: item.children,
                meta: item.meta,
            }, index);

            if (!newStore) {
                return;
            }

            let node: SingleLevelBoardStore<TMeta>;
            for (node of newStore.allNodes()) {
                this.index.set(node.id, node);
            }
        },
        remove: (id: string): void => {
            const store = this.index.get(id)?.parent?.remove(id);
            if (!store) {
                Logger.warn('BOARD:STORE', `Could not find item ${id}`);
                return;
            }
            let node: SingleLevelBoardStore<TMeta>;
            for (node of store.allNodes()) {
                this.index.remove(node.id);
            }
        },
        moveToIndex: (
            id: string,
            destinyIndex: number,
        ): MoveOperation<TreeItem<SingleLevelBoardStore<TMeta>>> | null => {
            const parentStore = this.index.get(id)?.parent;
            if (!parentStore) {
                Logger.warn(
                    'BOARD:STORE',
                    `Could not find parent of item "${id}" to move`,
                );
                return null;
            }
            const operation = parentStore.moveToIndex(id, destinyIndex);
            if (!operation) {
                return null;
            }
            return {
                ...operation,
                item: {
                    id: operation.item.id,
                    data: operation.item,
                    parentId: operation.item.parent?.id ?? null,
                    children: [],
                },
                result: operation.result.map((i) => ({
                    id: i.id,
                    data: i,
                    parentId: i.parent?.id ?? null,
                    children: [],
                })),
            };
        },
        /**
         * Update the properties of this item
         * @param id The item ID
         * @param updateItem The new properties and children
         * @param destinyIndex The index where the item should be moved
         *
         * @returns - The move operation result if moved
         */
        update: (
            id: string,
            updateItem: NirbyBoardStateNode<TMeta> | null,
            destinyIndex?: number,
        ): MoveOperation<TreeItem<NirbyBoardStateNode<TMeta>>> | null => {
            const store = this.index.get(id);
            if (!store) {
                return null;
            }
            if (updateItem) {
                store.update(updateItem.properties);
                store.setChildren(updateItem.children);
                store.updateMeta(updateItem.meta);
            }
            // noinspection SuspiciousTypeOfGuard
            if (typeof destinyIndex === 'number') {
                const operation = this.operations.moveToIndex(id, destinyIndex);
                if (!operation) {
                    return null;
                }
                return operation;
            }
            return null;
        },
    };

    private readonly history = new TreeChangeHistory<NirbyBoardStateNode<TMeta>>(
        new TreeChangeCRUD<NirbyBoardStateNode<TMeta>>(
            // find by id
            this.operations.findById.bind(this),
            // insert
            this.operations.insert.bind(this),
            // remove
            this.operations.remove.bind(this),
            // update
            this.operations.update.bind(this),
        ),
    );

    public readonly value$: Observable<NirbyBoardStateNode<TMeta, NirbyBoardItemStandard<TMeta>>>;

    /**
     * Watches changes on the tree.
     */
    public get changes$(): Observable<TreeChange<NirbyBoardStateNode<TMeta>>> {
        return this.history.changes$;
    }

    /**
     * The root level of the tree
     */
    public get root(): SingleLevelBoardStore<TMeta> {
        return this.controller;
    }

    /**
     * Constructor
     * @param rootState The initial state of this store
     */
    constructor(
        rootState: NirbyBoardStateNode<TMeta>,
    ) {
        this.controller = SingleLevelBoardStore.build(rootState);
        this.index = new MapSubject<string, SingleLevelBoardStore<TMeta>>(
            this.controller.allNodes().map((node) => [node.id, node]),
        );
        this.value$ = this.controller.value$;
    }

    /**
     * Get a node by its ID
     * @param id The node ID
     *
     * @returns The node or undefined if not found
     */
    get(id: string): SingleLevelBoardStore<TMeta> | undefined {
        return this.findById(id) ?? undefined;
    }

    /**
     * Iterate over all nodes
     */
    * entries(): Iterable<[string, SingleLevelBoardStore<TMeta>]> {
        for (const node of this.allNodes()) {
            yield [node.id, node];
        }
    }

    /**
     * Get the full tree state
     */
    public get value(): NirbyBoardStateNode<TMeta> {
        return this.controller.value;
    }

    /**
     * Get all nodes in the tree
     *
     * @returns The list of all nodes
     */
    public allNodes(): Iterable<SingleLevelBoardStore<TMeta>> {
        return this.index.value.values();
    }

    /**
     * Clear the history
     */
    public clearHistory(): void {
        this.history.clearHistory();
    }

    /**
     * Will go back in the history
     * @param times How many times to go back
     */
    public back(times = 1): void {
        this.history.goBack(times);
    }

    /**
     * Will go forward in the history
     * @param times How many times to go forward
     */
    public forward(times = 1): void {
        this.history.goForward(times);
    }

    /**
     * Watch an item store
     * @param id The id of the item to watch
     *
     * @returns - An observable that watches the store of the item with the given ID
     */
    public watchLevel(id: string): Observable<SingleLevelBoardStore<TMeta> | null> {
        return this.index.watch(id);
    }

    /**
     * Applies a canvas change
     * @param change The change to apply
     */
    public applyCanvasChange(change: ArtBoardItemChange): void {
        const current = this.index.get(change.id);
        if (!current) {
            Logger.warn(
                'STORE:APPLY-CANVAS-CHANGE',
                `Could not find block ${change.id}`,
            );
            return;
        }
        const properties = current.properties;
        properties.position = change.position;
        properties.rotation = change.rotation;
        properties.scale = change.scale;

        let newIndex: number | null = null;
        if (change.relativeZIndex === 'top') {
            newIndex = current.parent ? (current.parent.childrenCount - 1) : 0;
        } else if (change.relativeZIndex !== 0) {
            newIndex = current.index + change.relativeZIndex;
        }
        this.history.update(
            change.id,
            {
                id: current.id,
                type: current.type,
                properties,
                children: current.children.map((child) => child.value),
                meta: current.meta,
            },
            newIndex ?? undefined,
        );
    }

    /**
     * Adds an item to the board from an item's state
     * @param parentId The id of the parent item
     * @param state The state of the item to add
     *
     * @returns - The added item
     */
    public addAt(parentId: string, state: NirbyBoardStateNode<TMeta>): string | null {
        const store = this.findById(parentId);
        if (!store) {
            return null;
        }
        return this.history.insert(
            state.id,
            {
                id: state.id,
                type: state.type,
                properties: state.properties,
                children: state.children,
                meta: state.meta,
            },
            parentId,
            undefined,
            true,
        );
    }

    /**
     * Find an item by id
     * @param id The id of the item to find
     *
     * @returns - The item with the given id
     */
    public findById(id: string): SingleLevelBoardStore<TMeta> | null {
        return this.index.get(id);
    }

    /**
     * Removes an item from the board
     * @param id The id of the item to remove
     * @param saveHistory Whether to save the history
     */
    public remove(id: string, saveHistory = true): void {
        this.history.remove(id, saveHistory);
    }

    /**
     * Updates an item on the board
     * @param state The new state of the item
     */
    public update(state: NirbyBoardStateNode<TMeta>): void {
        this.history.update(state.id, state);
    }

    /**
     * Updates an item on the board giving some properties
     * @param id The id of the item to update
     * @param updatedProperties The properties to update
     */
    public updateMerge(
        id: string,
        updatedProperties: Partial<NirbyBoardStateNode<TMeta>['properties']>,
    ): void {
        const oldState = this.index.get(id)?.value;
        const oldProperties = oldState?.properties;
        if (!oldState || !oldProperties) {
            return;
        }
        const newProperties = {
            ...oldProperties,
            ...updatedProperties,
        } as NirbyBoardStateNode<TMeta>['properties'];
        this.history.update(id, {
            ...oldState,
            properties: newProperties,
        });
    }

    /**
     * Inserts an item on the board
     * @param id The id of the item to insert
     * @param state The state of the item to insert
     * @param parentId The id of the parent item
     * @param index Index where to insert the item
     * @param saveHistory Whether to save the history
     */
    public insert(
        id: string,
        state: NirbyBoardStateNode<TMeta>,
        parentId: string,
        index?: number,
        saveHistory = true,
    ): void {
        if (index === -1) {
            index = undefined;
        }
        this.history.insert(id, state, parentId, index, saveHistory);
    }

    /**
     * Moves an item within its siblings to the given index
     * @param id The id of the item to move
     * @param destinyIndex The index to move the item to. If null, the item will be moved to the end of the array
     */
    public move(id: string, destinyIndex: number | null): void {
        if (destinyIndex === null) {
            const siblings = this.findById(id)?.parent?.children;
            if (!siblings) {
                Logger.warnStyled(
                    'BLOCK-EDITOR-STORE:MOVE',
                    `Could not find siblings for item ${id}`,
                );
                return;
            }
            destinyIndex = siblings.length - 1;
        }
        this.history.update(id, null, destinyIndex);
    }

    /**
     * Moves an item within its siblings relative to its current index
     * @param id The id of the item to move
     * @param indexRelative The relative index to move the item to.
     */
    public moveRelative(id: string, indexRelative: number): void {
        if (indexRelative === 0) {
            return;
        }
        const siblings = this.findById(id)?.parent?.children;
        if (!siblings) {
            Logger.warnStyled(
                'BLOCK-EDITOR-STORE:MOVE',
                `Could not find siblings for item ${id}`,
            );
            return;
        }
        const currentIndex = siblings.findIndex((item) => item.value.id === id);
        const destinyIndex = currentIndex + indexRelative;
        this.history.update(id, null, destinyIndex);
    }

    /**
     * Starts a fast update process.
     *
     * Usage:
     * ```
     * const updater = new FastUpdater('an-id', history);
     * updater.update(newData1);
     * updater.update(newData2);
     * updater.update(newData3);
     * // after a while, a change will be registered in the history
     * updater.dispose();
     * ```
     * @param id ID of the item to update
     * @param restTime The time to wait before registering the change
     *
     * @returns - A {@link QuickUpdater} instance to handle fast updates in an optimized way.
     *
     * @see QuickUpdater
     */
    public startQuickUpdate(
        id: string,
        restTime = 500,
    ): QuickUpdater<NirbyBoardStateNode<TMeta>> {
        return this.history.startQuickUpdate(id, restTime);
    }

    /**
     * Updates the meta of an item
     * @param newMeta The new meta of the item
     * @param id The id of the item to update
     */
    updateMergeMeta(newMeta: Partial<TMeta>, id: string): void {
        const item = this.findById(id);
        const meta = item?.meta;

        if (!item || !meta) {
            return;
        }
        const updatedMeta: TMeta = {
            ...meta,
            ...newMeta,
        };
        item.updateMeta(updatedMeta);
        this.metaChangesSubject.next({
            id,
            type: 'update',
            newValue: updatedMeta,
            previousValue: meta,
        });
    }

    /**
     * Gets the index of an item
     * @param id The id of the item to get the index of
     *
     * @returns - The index of the item
     */
    indexOf(id: string): number {
        return this.index.get(id)?.index ?? -1;
    }
}
