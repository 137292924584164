import Konva from 'konva';
import {BehaviorSubject, first, fromEvent, Observable} from 'rxjs';
import {AnchorName, anchorNameToHandler, ResizeHandler, Transformation} from './transformation';
import {ArtBoardItem} from './art-board-item';

export class ArtBoardTransformer<TMeta, TB extends ArtBoardItem<TMeta> = ArtBoardItem<TMeta>> {
    private readonly transformer: Konva.Transformer;
    private readonly selectedSubject = new BehaviorSubject<TB | null>(null);

    get selected$(): Observable<TB | null> {
        return this.selectedSubject.asObservable();
    }

    get selected(): TB | null {
        return this.selectedSubject.value;
    }

    set selected(value) {
        this.select(value);
    }

    get selectedNodes(): Konva.Node[] {
        return this.transformer.nodes();
    }

    constructor(public readonly layer: Konva.Layer) {
        this.transformer = new Konva.Transformer();
        this.layer.add(this.transformer);
    }

    /**
     * Set the art board item to be controlled by the transformer. Specifically:
     * - Adds the given item to the transformer
     * - Enables only some anchors to be used for resizing
     * - Marks the transformer to do not keep aspect ratio
     * - Sets the bound box to limit the resizing
     * @param item The art board item to select
     */
    public select(item: TB | null): void {
        if (this.selected) {
            this.selected.transformController.finish();
        }
        this.selectedSubject.next(item);
        const node = item?.shape;

        if (!node) {
            this.transformer.nodes([]);
            return;
        }

        const nodes = node ? [node] : [];

        this.transformer.nodes(nodes);
        if (!item) {
            return;
        }

        this.transformer.keepRatio(item.KEEP_ASPECT_RATIO);
        this.transformer.enabledAnchors([...item.ANCHORS]);

        // rotation snaps to 45 degrees
        this.transformer.rotationSnaps([
            0, 45, 90, 135, 180, 225, 270, 315, 360,
        ]);

        let lastHandler: ResizeHandler | null = null;

        const resetHandler = () => (lastHandler = null);
        fromEvent(this.transformer, 'transformend')
            .pipe(first())
            .subscribe(() => {
                resetHandler();
            });

        this.transformer.boundBoxFunc((oldBox, newBox) => {
            const anchorName = this.transformer.getActiveAnchor();
            // on rotation
            if (anchorName === 'rotater') {
                lastHandler = null;
                return newBox;
            }

            // on resize
            const handler = anchorNameToHandler(
                this.transformer._movingAnchorName as AnchorName,
            );
            const box = {...newBox};
            let brokeConstraints = false;
            const absoluteScale = nodes[0].getAbsoluteScale();

            // FIXME: Fix scaling when grabbing left or top anchor

            // return old width and x position if width is less than min width
            if (
                item.MIN_WIDTH > 0 &&
                box.width < item.MIN_WIDTH * absoluteScale.x
            ) {
                box.x = oldBox.x;
                box.y = oldBox.y;
                box.width = oldBox.width;
                brokeConstraints = true;
            }
            // return old height and y position if height is less than min height
            if (
                item.MIN_HEIGHT > 0 &&
                box.height < item.MIN_HEIGHT * absoluteScale.y
            ) {
                box.x = oldBox.x;
                box.y = oldBox.y;
                box.height = oldBox.height;
                brokeConstraints = true;
            }
            if (brokeConstraints) {
                return box;
            }

            lastHandler = handler;
            return newBox;
        });
    }

    public get current(): Transformation | null {
        const transformer = this.transformer;
        if (!transformer) {
            return null;
        }
        const anchor = transformer.getActiveAnchor();
        // Ignore deprecated symbol ``window.event`` until another option is available
        // noinspection JSDeprecatedSymbols
        const evt: MouseEvent = window.event as MouseEvent;
        if (anchor === 'rotater') {
            return {type: 'rotation'};
        }
        if (
            anchor === 'top-left' ||
            anchor === 'top-right' ||
            anchor === 'bottom-left' ||
            anchor === 'bottom-right'
        ) {
            return {
                type: 'sizeChange',
                event: evt,
                handler: {
                    vertical: anchor.split('-')[0] as 'top' | 'bottom',
                    horizontal: anchor.split('-')[1] as 'left' | 'right',
                },
            };
        }
        if (anchor === 'middle-left' || anchor === 'middle-right') {
            return {
                type: 'widthChange',
                event: evt,
                handler: {
                    vertical: 'middle',
                    horizontal: anchor.split('-')[1] as 'left' | 'right',
                },
            };
        }
        if (anchor === 'top-center' || anchor === 'bottom-center') {
            return {
                type: 'heightChange',
                event: evt,
                handler: {
                    vertical: anchor.split('-')[0] as 'top' | 'bottom',
                    horizontal: 'center',
                },
            };
        }
        return null;
    }
}
