import {buildActionSchema, GenericAction} from './action.model';
import {HREF_TARGET_SCHEMA, HRefTarget} from './go-to-url.action.model';
import {TRIGGER_DOM_EVENTS_SCHEMA, TriggerDomEvents} from './toggle-dom.action.model';
import {VARIABLE_OPERATION_SCHEMA, VariableOperation} from './variable-update.action.model';
import {NIRBY_VARIABLE_NULLABLE_SCHEMA, NirbyVariableNullable} from '@nirby/runtimes/state';
import {VIDEO_RECORD_SCHEMA, VideoRecord} from '@nirby/media-models';
import {OPEN_FORM_SETTINGS_SCHEMA, OpenFormSettings} from './open-form-settings';
import {GO_TO_PRIME_SETTINGS_SCHEMA, GoToPrimeSettings} from './go-to-prime.model';
import {GO_BACK_SETTINGS_SCHEMA, GoBackSettings} from './go-back.model';
import {z, ZodSchema} from 'zod';
import {ZodSchemaBuild} from '@nirby/store/models';
import {CONDITIONAL_SCHEMA} from '@nirby/conditionals';

export type CardLinkAction = GenericAction<
    'card-link',
    {
        key: string | null;
    }
>;
/**
 * Action to change cards
 */
export const CARD_LINK_ACTION = buildActionSchema<CardLinkAction>(
    'card-link' as const,
    z.object({
        key: z.string().nullable().default(null),
    }),
);

/**
 * Action to follow a URL
 */
export type GoToURLAction = GenericAction<
    'go-to-url',
    {
        href: string;
        target: HRefTarget;
    }
>;
export const GO_TO_URL_ACTION = buildActionSchema<GoToURLAction>(
    'go-to-url' as const,
    z.object({
        href: z.string(),
        target: HREF_TARGET_SCHEMA.catch((): HRefTarget => '_self'),
    }),
);

/**
 * Action to go to another video
 */
export type VideoLinkAction = GenericAction<
    'video-link',
    {
        key: string | null;
        time: number;
        ignoreAggroGeneration: boolean;
    }
>;
export const VIDEO_LINK_ACTION = buildActionSchema<VideoLinkAction>(
    'video-link' as const,
    z.object({
        key: z.string().nullable(),
        time: z.number(),
        ignoreAggroGeneration: z.boolean().default(false),
    }),
);

export type EmbedAction = GenericAction<
    'open-embed',
    {
        src: string;
    }
>;
/**
 * Action to open a lightbox
 */
export const EMBED_ACTION = buildActionSchema<EmbedAction>(
    'open-embed' as const,
    z.object({
        src: z.string(),
    }),
);

export type PrimePauseAction = GenericAction<
    'prime-pause',
    {
        delay: number;
    }
>;
/**
 * Action to pause a Prime
 */
export const PRIME_PAUSE_ACTION = buildActionSchema<PrimePauseAction>(
    'prime-pause' as const,
    z.object({
        delay: z.number().default(0),
    }),
);

/**
 * Action to trigger a DOM action
 */
export type TriggerDomAction = GenericAction<
    'trigger-dom',
    {
        selector: string;
        event: TriggerDomEvents;
    }
>;
export const TRIGGER_DOM_ACTION = buildActionSchema<TriggerDomAction>(
    'trigger-dom' as const,
    z.object({
        selector: z.string(),
        event: TRIGGER_DOM_EVENTS_SCHEMA,
    }),
);

/**
 * Action to close the current product
 */
export type CloseAction = GenericAction<'close', object>;
export const CLOSE_ACTION = buildActionSchema<CloseAction>(
    'close' as const,
    z.object({}),
);

/**
 * Action to update a variable
 */
export type VariableUpdateAction = GenericAction<
    'variable-update',
    {
        operation: VariableOperation;
        amount: NirbyVariableNullable;
        variable: string;
    }
>;
export const VARIABLE_UPDATE_ACTION = buildActionSchema<VariableUpdateAction>(
    'variable-update' as const,
    z.object({
        operation: VARIABLE_OPERATION_SCHEMA,
        amount: NIRBY_VARIABLE_NULLABLE_SCHEMA,
        variable: z.string(),
    }),
);

/**
 * Action to open a video
 */
export type VideoAction = GenericAction<'video', VideoRecord | null>;
export const VIDEO_ACTION = buildActionSchema<VideoAction>(
    'video' as const,
    VIDEO_RECORD_SCHEMA.nullable(),
);

/**
 * Action to trigger a custom analytics event
 */
export type TrackEventAction = GenericAction<
    'track-event',
    {
        event: string;
        tags: string[];
    }
>;
export const TRACK_EVENT_ACTION = buildActionSchema<TrackEventAction>(
    'track-event' as const,
    z.object({
        event: z.string(),
        tags: z.array(z.string()).default([]),
    }),
);

/**
 * Action to answer a question
 */
export type AnswerQuestionAction = GenericAction<
    'answer-question',
    {
        questionId: string;
        question: string;
        answer: NirbyVariableNullable;
    }
>;
export const ANSWER_QUESTION_ACTION = buildActionSchema<AnswerQuestionAction>(
    'answer-question',
    z.object({
        questionId: z.string(),
        question: z.string(),
        answer: NIRBY_VARIABLE_NULLABLE_SCHEMA,
    }),
);

/**
 * Action to request contact information
 *
 * This must use the configuration set by the product
 */
export type OpenFormAction = GenericAction<'open-form', OpenFormSettings>;
export const OPEN_FORM_ACTION = buildActionSchema<OpenFormAction>(
    'open-form' as const,
    OPEN_FORM_SETTINGS_SCHEMA,
);

/**
 * Action to go to a source on another Prime
 */
export type GoToPrimeAction = GenericAction<'go-to-prime', GoToPrimeSettings>;
export const GO_TO_PRIME_ACTION = buildActionSchema<GoToPrimeAction>(
    'go-to-prime' as const,
    GO_TO_PRIME_SETTINGS_SCHEMA,
);

/**
 * Action to go back to the previous source
 */
export type GoBackAction = GenericAction<'go-back', GoBackSettings>;
export const GO_BACK_ACTION = buildActionSchema<GoBackAction>(
    'go-back' as const,
    GO_BACK_SETTINGS_SCHEMA,
);

export type NirbyAction =
    | CardLinkAction
    | GoToURLAction
    | CloseAction
    | TriggerDomAction
    | VideoAction
    | VariableUpdateAction
    | PrimePauseAction
    | VideoLinkAction
    | OpenFormAction
    | TrackEventAction
    | AnswerQuestionAction
    | EmbedAction
    | GoToPrimeAction
    | GoBackAction;

export const NIRBY_ACTION_SCHEMA = z.union([
    CARD_LINK_ACTION,
    GO_TO_URL_ACTION,
    CLOSE_ACTION,
    TRIGGER_DOM_ACTION,
    VIDEO_ACTION,
    VARIABLE_UPDATE_ACTION,
    PRIME_PAUSE_ACTION,
    VIDEO_LINK_ACTION,
    OPEN_FORM_ACTION,
    TRACK_EVENT_ACTION,
    ANSWER_QUESTION_ACTION,
    EMBED_ACTION,
    GO_TO_PRIME_ACTION,
    GO_BACK_ACTION,
]);

export const SCHEMA_BY_TYPE: {
    [BlockType in NirbyAction['type']]: ZodSchemaBuild<
        PickAction<NirbyAction, BlockType>
    >;
} = {
    'card-link': CARD_LINK_ACTION,
    'go-to-url': GO_TO_URL_ACTION,
    close: CLOSE_ACTION,
    'trigger-dom': TRIGGER_DOM_ACTION,
    video: VIDEO_ACTION,
    'variable-update': VARIABLE_UPDATE_ACTION,
    'prime-pause': PRIME_PAUSE_ACTION,
    'video-link': VIDEO_LINK_ACTION,
    'open-form': OPEN_FORM_ACTION,
    'track-event': TRACK_EVENT_ACTION,
    'answer-question': ANSWER_QUESTION_ACTION,
    'open-embed': EMBED_ACTION,
    'go-to-prime': GO_TO_PRIME_ACTION,
    'go-back': GO_BACK_ACTION,
};

/**
 * Returns the migrator for a block type
 * @param type The type of the block
 *
 * @returns - The migrator for the block
 */
function actionSchemaFactory<T extends NirbyAction>(
    type: string,
): ZodSchema<T> | null {
    const migrator = SCHEMA_BY_TYPE[type as NirbyAction['type']];
    if (migrator === undefined) {
        return null;
    }
    return migrator as ZodSchema<T>;
}

export const ANY_CARD_ACTION_SCHEMA = z
    .object({
        id: z.string().nullable().default(null),
        type: z.string(),
        options: z.unknown(),
        if: CONDITIONAL_SCHEMA.nullable().default(null),
    })
    .transform((value) => {
        const schema = actionSchemaFactory(value.type);
        if (schema === null) {
            throw new Error(`Unknown action type: ${value.type}`);
        }
        return schema.parse(value);
    });

export type NirbyActionType = NirbyAction['type'];
export type PickAction<
    Action extends NirbyAction,
    ActionType extends NirbyActionType
> = Action extends {
        type: ActionType;
    }
    ? Action
    : never;

export type PickStandardAction<ActionType extends NirbyActionType> =
    PickAction<NirbyAction, ActionType>;

export type NirbyActionTrigger = 'click' | 'hover';

export type NirbyActionsByEvent = {
    [trigger in NirbyActionTrigger]: NirbyAction[];
};
