import {NirbyProductType} from '../typings';
import {z} from 'zod';
import {ZodSchemaBuild} from '@nirby/store/models';

export type ProductVersion = 'legacy' | 'v1';

export const PRODUCT_TYPES: NirbyProductType[] = ['pop', 'prime'];

export const PRODUCT_COLLECTIONS: Record<NirbyProductType, Record<ProductVersion, string>> = {
    pop: {
        legacy: 'campaigns',
        v1: 'campaigns',
    },
    prime: {
        legacy: 'primes',
        v1: 'v1-primes',
    },
};

export const PRODUCT_VERSION_SCHEMA: ZodSchemaBuild<ProductVersion> = z.enum(['legacy', 'v1'] as const);
export const PRODUCT_TYPE_SCHEMA: ZodSchemaBuild<NirbyProductType> = z.enum(['pop', 'prime'] as const);
