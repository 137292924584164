/**
 * Class to encode and decode custom objects.
 *
 * You can assign a reducer function to apply to the object before encoding. This is useful when you need to serialize
 * an object cannot be serialized using {@link JSON.stringify}.
 */
import {CustomEncoder} from './encoder-custom';
import {Encoder} from './encoder';

/**
 * Defines a translator to get a value to TA from a value of TOther.
 */
export class EncoderTranslatorSingle<TA, TOther> {
    /**
     * Constructor.
     * @param encoder The encoder to use.
     * @param transformer The transformer to use.
     */
    constructor(
        private readonly encoder: CustomEncoder<TOther, unknown>,
        private readonly transformer: (destiny: TOther) => TA | null,
    ) {
    }

    /**
     * Decodes and translates the value into type TA.
     * @param encoded The encoded value.
     *
     * @returns The decoded and translated value.
     */
    public decode(encoded: string): TA | null {
        return this.transformer(this.encoder.decode(encoded)) ?? null;
    }
}

/**
 * Proxy to encode and decode custom objects of different types to a given type ``TA``.
 * When assigning a translator item through the {@link EncoderTranslator.set} method, the encoder will be registered
 * into this object, and objects encoded through another {@link EncoderTranslator} will be decoded using this encoder
 * into this type.
 */
export class EncoderTranslator<TA> implements Encoder<TA> {
    private readonly transformers = new Map<string, EncoderTranslatorSingle<TA, unknown>>();

    /**
     * Constructor.
     * @param itemTypeId The ID used to identify the type of the item encoded by this encoder.
     * @param encoder The encoder to use.
     */
    constructor(
        private readonly itemTypeId: string,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        public readonly encoder: CustomEncoder<TA, any>,
    ) {
    }

    /**
     * Sets a transformer to convert another itemType to this itemType when found on the clipboard.
     * @param otherEncoder The encoder of the type to translate from.
     * @param transformer The transformer to use.
     */
    public setConverter<TOther>(
        otherEncoder: EncoderTranslator<TOther>,
        transformer: (value: TOther) => TA | null,
    ): void {
        const item = new EncoderTranslatorSingle<TA, TOther>(
            otherEncoder.encoder,
            (value: unknown) => transformer(value as TOther),
        );
        this.transformers.set(
            otherEncoder.itemTypeId,
            item as EncoderTranslatorSingle<TA, unknown>,
        );
    }

    /**
     * Encodes a value.
     * @param value The value to encode.
     *
     * @returns The encoded value.
     */
    encode(value: TA): string {
        return this.itemTypeId + '|' + this.encoder.encode(value);
    }

    /**
     * Decodes a value.
     * @param value The value to decode.
     *
     * @returns The decoded value.
     */
    decode(value: string): TA | null {
        const split = value.split('|');
        if (split.length !== 2) {
            return null;
        }
        const [itemType, encoded] = split;
        if (itemType === this.itemTypeId) {
            return this.encoder.decode(encoded);
        }
        const transformer = this.transformers.get(itemType);
        if (transformer) {
            return transformer.decode(encoded);
        }
        return null;
    }
}
