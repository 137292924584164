import { NirbyAction } from '@nirby/models/actions';
import {ActionTrigger, BaseBlock, BlockActions, Card} from '@nirby/models/nirby-player';
import {v4} from 'uuid';
import {generateId} from './id';


/**
 * Deep copy an object of triggers with arrays of actions.
 * @param actions Actions to copy.
 *
 * @returns The copied actions.
 */
export function deepCopyActions<T extends { [trigger: string]: NirbyAction[] }>(actions: BlockActions): T {
    return Object.fromEntries(
        Object.entries(actions)
            .map(([trigger, actions]): [ActionTrigger, NirbyAction[]] => [
                trigger,
                actions.map(<T extends NirbyAction>(action: T): T => ({
                    id: v4(),
                    type: action.type,
                    options: {...action.options},
                    if: action.if ? {...action.if} : null,
                } as T))
            ])
    ) as T;
}

/**
 * Deep copy a block.
 * @param block Block to copy.
 * @param generateNewId Whether to generate a new ID for the block.
 *
 * @returns The copied block.
 */
export function deepCopyBlock<TB extends BaseBlock<any, any, any>>(
    block: TB,
    generateNewId = false
): TB {
    return {
        content: {...block.content},
        style: {...block.style},
        actions: deepCopyActions(block.actions),
        position: [{...block.position[0]}, {...block.position[1]}],
        rotation: block.rotation,
        type: block.type,
        hash: generateNewId ? generateId(16) : block.hash,
    } as TB;
}

/**
 * Deep copy a card.
 * @param card Card to copy.
 *
 * @returns The copied card.
 */
export function deepCopyCard(card: Card): Card {
    return {
        ...card,
        blocks: card.blocks.map((b) => deepCopyBlock(b)),
    };
}
