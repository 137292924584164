import {defaultCard, defaultVideoCard} from './card';
import {defaultActions} from './actions';
import defaultBlocks from '../blocks/defaults';

export const defaults = {
    card: defaultCard,
    videoCard: defaultVideoCard,
    blocks: defaultBlocks,
    actions: defaultActions,
};

export const defaultConfig = {
    defaults,
    minCardAppearanceLength: 10000,
    videoGridSize: [10, 7],
    cardBorderRadius: 10,
    cardSize: {
        x: 350,
        y: 580,
    },
    responsive: {
        baseDimension: 600,
        baseFontSizePx: 13,
    },
    api: {
        host: 'http://localhost:8080',
    },
    vimeo: {
        apiKey: '303c9759f9a6dff82c4cbab97e78daff',
    },
};

export type NirbyPlayerConfig = typeof defaultConfig;

export * from './constants';
export * from '../blocks/defaults';
export * from './card';
export * from './actions';
