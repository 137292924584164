import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output} from '@angular/core';
import {FormGroup, UntypedFormControl, ValidatorFn, Validators} from '@angular/forms';
import {NirbyContext} from '@nirby/runtimes/context';
import {NirbyVariableNullable} from '@nirby/runtimes/state';
import {Logger} from '@nirby/logger';
import {optionalValidator} from '../../../validators';
import {ContactAttributeType, FormFieldDeclaration, OpenFormSettings} from '@nirby/models/actions';
import {NgTranslator} from '../../../services';

interface ControlDescription<T = NirbyVariableNullable> {
    autocomplete?: string;
    hint?: string;
    default: T;
    validators?: ValidatorFn | ValidatorFn[];
}

interface AttributeControl<T = NirbyVariableNullable> {
    description: ControlDescription<T>;
    declaration: FormFieldDeclaration;
    control: UntypedFormControl;
}

@Component({
    selector: 'nirby-contact-form',
    templateUrl: './contact-form.component.html',
    styleUrls: ['./contact-form.component.scss'],
})
/**
 * The contact form component.
 */
export class ContactFormComponent implements OnInit, OnChanges, OnDestroy {
    errored = false;
    controlDescriptions: Record<ContactAttributeType, ControlDescription> = {
        email: {
            default: '',
            hint: 'user@mail.com',
            validators: Validators.email,
            autocomplete: 'email',
        },
        string: {
            default: '',
        },
    };

    public readonly DEFAULT_PLACEHOLDER = 'FORMS.PLACEHOLDER';
    @Input() public context = new NirbyContext(this.translator);

    /**
     * The Form options
     *
     * * ``type``: The Form type ('contact', 'question')
     * * ``title``: The form title
     * * ``description``: The form description
     * * ``attributes``: The form attributes (Should be one when type is 'question')
     */
    @Input() public options: OpenFormSettings = {
        type: 'contact',
        title: 'Form',
        description: null,
        attributes: [],
    };
    @Output() submitForm = new EventEmitter<Record<string, NirbyVariableNullable>>();

    attributes: AttributeControl[] = [];

    form = new FormGroup({});

    /**
     * Constructor.
     * @param translator The translator service
     */
    constructor(
        private readonly translator: NgTranslator,
    ) {
    }

    /**
     * Clear the form group and initialize it again
     */
    ngOnInit(): void {
        this.clearFormGroup();
        this.initFormGroup();
    }

    /**
     * Loads the form attributes
     */
    initFormGroup(): void {
        this.attributes = this.options.attributes.map((declaration) => {
            const description = this.controlDescriptions[declaration.type];
            let validators = description.validators ?? [];
            if (!Array.isArray(validators)) {
                validators = [validators];
            }
            if (declaration.required) {
                validators.push(Validators.required);
            } else {
                validators = optionalValidator(validators);
            }
            const currentValue =
                this.context.contact.attributes.getStringNullable(
                    declaration.id,
                );
            return {
                declaration,
                description,
                control: new UntypedFormControl(
                    currentValue ?? description.default,
                    validators,
                ),
            };
        });

        let attribute: AttributeControl;
        for (attribute of this.attributes) {
            this.form.addControl(attribute.declaration.id, attribute.control);
        }
    }

    /**
     * Clears the form group
     */
    clearFormGroup(): void {
        const names = Object.keys(this.form.controls);
        names.forEach((n) => this.form.removeControl(n));
        this.attributes = [];
    }

    /**
     * Restarts the form
     */
    ngOnChanges(): void {
        this.clearFormGroup();
        this.initFormGroup();
    }

    /**
     * Submits the form:
     * - Emits an event with the form values
     * - Load the variables marked with the 'store' flag to the contact
     */
    async sendSubmit(): Promise<void> {
        try {
            this.errored = false;
            this.form.disable();
            const answer: Record<string, NirbyVariableNullable> =
                this.form.value;
            this.attributes
                .filter((a) => a.declaration.store)
                .forEach((a) => {
                    this.context.memory.set(a.declaration.id, a.control.value);
                });
            this.submitForm.emit(answer);
        } catch (e) {
            Logger.errorStyled('CONTACT:ERROR', e);
            this.errored = true;
            this.form.enable();
        }
    }

    /**
     * Clears the form group
     */
    ngOnDestroy(): void {
        this.clearFormGroup();
    }

    /**
     * Checks if the form is valid and returns a human-readable error message
     * @param control The form control to validate
     *
     * @returns - True if the form is valid
     */
    getControlErrorMessage(control: UntypedFormControl): string | null {
        if (control.touched && control.errors) {
            if (control.errors['required']) {
                return 'This field is required';
            }
            if (control.errors['email']) {
                return 'This field must contain a valid e-mail';
            }
            return 'This field is invalid';
        }
        return null;
    }

    /**
     * Submits the form when a line break is inserted
     * @param event The input event
     * @param submitButton The submit button
     */
    onBeforeInput(event: InputEvent, submitButton: HTMLButtonElement): void {
        if (event.inputType === 'insertLineBreak') {
            event.preventDefault();
            submitButton.click();
        }
    }
}
