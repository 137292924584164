import {z, ZodTypeDef} from 'zod';
import {Logger} from '@nirby/logger';
import {DateTime} from 'luxon';
import {ModelMetadata, ZodSchemaBuild} from '@nirby/store/models';

export interface MigrationResult<T extends object> {
    wasMigrated: boolean;
    migrated: T;
}

export interface MigratorLike<Model extends object> {
    expectedSchema: ZodSchemaBuild<Model>;
    readonly currentVersion: number;

    migrate(model: unknown): MigrationResult<Model>;
}


/**
 * An Entry for a migrator that transforms a model that has a version that satisfies the given schema
 * into another.
 *
 * The schema that the model must satisfy to be migrated.
 * If the model does not satisfy this schema, it will not be migrated.
 */
export class MigrationEntry<Source extends object, Model extends object> {
    /**
     * Constructor.
     *
     * @param schema The schema that the model must satisfy to be migrated.
     * If the model does not satisfy this schema, it will not be migrated.
     * @param transformer The transformer that will be used to migrate the model if it satisfies the schema.
     *
     * @throws Error if the schema does not have a version field.
     */
    constructor(
        private readonly schema: z.ZodSchema<Source, ZodTypeDef, unknown>,
        private readonly transformer: (model: Source) => Model,
    ) {
    }

    /**
     * Migrates the given model if it satisfies the schema.
     * @param model The model to migrate.
     *
     * @returns The migrated model, or the original model if it did not satisfy the schema.
     */
    tryMigrate(model: unknown): Model | null {
        const result = this.schema.safeParse(model);
        if (result.success) {
            return this.transformer(result.data);
        }
        return null;
    }
}

/**
 * A transformer that migrates a database object to a newer version, and fixes any missing fields.
 */
export class Migrator<Model extends object> implements MigratorLike<Model> {
    readonly #entries: MigrationEntry<object, Model>[] = [];
    public readonly currentVersion = 7;

    /**
     * Parses the metadata of a model.
     * @param model The model to parse.
     * @param timestampSchema The schema of the timestamp.
     *
     * @returns The parsed metadata.
     */
    public static parseMetadata(
        model: unknown,
        timestampSchema: ZodSchemaBuild<DateTime>,
    ): ModelMetadata {
        const result: ZodSchemaBuild<ModelMetadata> = z.object({
            _creationTime: timestampSchema.catch(() => DateTime.now()),
            _lastUpdate: timestampSchema.catch(() => DateTime.now()),
            _databaseVersion: z.number().catch(() => 7),
        });
        return result.parse(model);
    }

    /**
     * Constructor.
     * @param expectedSchema The expected zod schema of the model.
     * @param defaultModelFn The function that will be used to create a default model if the migration fails.
     */
    constructor(
        public readonly expectedSchema: z.ZodSchema<Model, ZodTypeDef, unknown>,
        public readonly defaultModelFn: () => Model,
    ) {
    }

    /**
     * Creates a migrator that allows anything.
     *
     * @returns The migrator.
     */
    static any<Model extends object>(): Migrator<Model> {
        return new Migrator<Model>(z.any(), () => ({} as never));
    }

    /**
     * The entries of the migrator.
     */
    public get entries(): ReadonlyArray<MigrationEntry<object, Model>> {
        return this.#entries;
    }

    /**
     * Adds a migration entry to the migrator.
     * @param entry The entry to add.
     *
     * @returns The migrator.
     */
    addEntry<Source extends object>(
        entry: MigrationEntry<Source, Model>,
    ): Migrator<Model> {
        this.#entries.push(entry as unknown as MigrationEntry<object, Model>);
        return this;
    }

    /**
     * Migrates the given model to the latest version.
     * @param model The model to migrate.
     * @param strict Whether to throw an error if the model does not satisfy the expected schema.
     *
     * @returns The migrated model.
     */
    migrate(model: unknown, strict = false): MigrationResult<Model> {
        const result = this.expectedSchema.safeParse(model);
        if (result.success) {
            return {
                wasMigrated: false,
                migrated: result.data,
            };
        }
        for (const entry of this.#entries) {
            const migrated = entry.tryMigrate(model);
            if (migrated) {
                return {
                    wasMigrated: true,
                    migrated,
                };
            }
        }
        if (strict) {
            throw result.error;
        }
        Logger.error(
            `Could not migrate model (${
                this.expectedSchema.description ?? 'Unknown'
            })`,
            model,
            result.error.format(),
        );
        return {
            wasMigrated: false,
            migrated: this.defaultModelFn(),
        };
    }
}

export type InferMigrator<M extends Migrator<object>> = M extends Migrator<infer T> ? T : never;
