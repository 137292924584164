// noinspection JSUnusedLocalSymbols

import {BehaviorSubject, filter, Observable, pairwise} from 'rxjs';
import {distinctUntilChanged, map} from 'rxjs/operators';
import {NirbyVariable, NirbyVariableNullable, PartialRecord} from '@nirby/runtimes/state';
import {
    BOOL_CONVERTER,
    Converter,
    FLOAT_CONVERTER,
    INT_CONVERTER,
    STRING_CONVERTER,
    WeakAttributes,
    WeakTypedState,
} from './weak-typed-state';
import {VariableUpdateAction} from '@nirby/models/actions';
import {Logger} from '@nirby/logger';


/**
 * A state manager is a container for variables that allows you to store, retrieve and watch them through
 * observables.
 *
 * @export
 * @class NirbyMemory
 *
 * @example
 * const memory = new NirbyMemoryHost();
 *
 * memory.get('foo'); // null
 *
 * memory.watch('foo').subscribe(value => {
 *   console.log(value);
 * });
 *
 * memory.set('foo', 'bar'); // At this point, the value will be emitted to the subscribers
 * memory.get('foo'); // 'bar'
 *
 * memory.get('bar'); // null
 *
 */
export interface StateManager {
    get state(): WeakTypedState;

    get stateOfLevel(): WeakTypedState;

    get state$(): Observable<WeakTypedState>;

    get stateOfLevel$(): Observable<WeakTypedState>;

    get keys(): string[];

    get(name: string): NirbyVariableNullable;

    // SETTER
    set<T extends NirbyVariableNullable | PartialRecord<NirbyVariableNullable>>(name: string, value: T): void;

    // GETTERS
    getBool(name: string): boolean;

    getString(name: string): string;

    getInt(name: string): number;

    getFloat(name: string): number;

    // GETTERS NULLABLE
    getBoolNullable(name: string): boolean | null;

    getStringNullable(name: string): string | null;

    getIntNullable(name: string): number | null;

    getFloatNullable(name: string): number | null;

    // WATCHERS
    watchBool(key: string): Observable<boolean>;

    watchString(key: string): Observable<string>;

    watchFloat(key: string): Observable<number>;

    watchInt(key: string): Observable<number>;

    // CLEAR

    /**
     * Clear the current state
     */
    clear(newValue?: WeakAttributes): void;

    // MASK

    /**
     * Allows you to create a new memory mask with a prefix appended to the current one.
     * @param prefix The prefix to append to the current prefix.
     *
     * @example
     * const memory = new NirbyMemory();
     * const fooMask = new MemoryMask('foo', memory);
     * const fooBarMask = mask.mask('bar');
     *
     * fooBarMask.set('baz', 'abc');
     * fooBarMask.get('baz'); // 'abc'
     * fooMask.get('bar.baz'); // 'abc'
     * memory.get('foo.bar.baz'); // 'abc'
     */
    mask(prefix: string): StateManager;

    get parent(): StateManager | null;

    /**
     * Maps a string with variable references to use the current state variables.
     * @param content
     */
    transform(content: string): string;
}

/**
 * A state manager that hosts a state.
 */
export class NirbyMemory<TK extends string = string>
    implements StateManager {

    /**
     * Constructor.
     * @param initialState The initial state.
     */
    constructor(initialState?: WeakAttributes) {
        this.subject = new BehaviorSubject<WeakTypedState>(
            new WeakTypedState(initialState),
        );
    }

    /**
     * Watches the current state.
     */
    public get state$(): Observable<WeakTypedState> {
        return this.subject.asObservable();
    }

    /**
     * Watches only the current levels state.
     */
    public get stateOfLevel$(): Observable<WeakTypedState> {
        return this.state$.pipe(
            map((state) =>
                state.filterKeys((key) => key.split('.').length === 1),
            ),
        );
    }

    /**
     * Returns the current state of the memory. Considering children variables too.
     *
     * @see stateOfLevel
     */
    public get state(): WeakTypedState {
        return this.subject.value;
    }

    /**
     * Updates the full state of the memory.
     * @param value The new state.
     * @private
     */
    private set state(value: WeakTypedState) {
        this.subject.next(value);
    }

    /**
     * Returns the current state considering only this level (i.e. no children variables).
     */
    public get stateOfLevel(): WeakTypedState {
        return this.state.filterKeys((key) => key.split('.').length === 1);
    }

    /**
     * @param value The value to set.
     * @private
     * @deprecated Prefer the {@link state} setter
     */
    private set memory(value: WeakTypedState) {
        this.state = value;
    }

    private subject: BehaviorSubject<WeakTypedState>;

    /**
     * Replaces the variables in a string with the current state.
     * @param content The content to transform.
     *
     * @returns - The transformed string.
     */
    public transform(content: string): string {
        return this.state.transform(content);
    }

    /* GETTERS */

    /**
     * Get a value from the state.
     * @param name The name of the value.
     *
     * @returns - The value.
     */
    get(name: string): NirbyVariableNullable {
        return this.state.value[name] ?? null;
    }

    /**
     * Get a value from the state as a boolean.
     * @param name The name of the value.
     *
     * @returns - The value.
     */
    getBool(name: string): boolean {
        return this.state.getBool(name);
    }

    /**
     * Get a value from the state as a string.
     * @param name The name of the value.
     *
     * @returns - The value.
     */
    getString(name: string): string {
        return this.state.getString(name);
    }

    /**
     * Get a value from the state as an integer.
     * @param name The name of the value.
     *
     * @returns - The value.
     */
    getInt(name: string): number {
        return this.state.getInt(name);
    }

    /**
     * Get a value from the state as a float.
     * @param name The name of the value.
     *
     * @returns - The value.
     */
    getFloat(name: string): number {
        return this.state.getFloat(name);
    }

    /* GETTERS NULLABLE */

    /**
     * Get a value from the state as a boolean, or null if it doesn't exist.
     * @param name The name of the value.
     *
     * @returns - The value.
     */
    getBoolNullable(name: string): boolean | null {
        return this.state.getBoolNullable(name);
    }

    /**
     * Get a value from the state as a string, or null if it doesn't exist.
     * @param name The name of the value.
     *
     * @returns - The value.
     */
    getStringNullable(name: string): string | null {
        return this.state.getStringNullable(name);
    }

    /**
     * Get a value from the state as an integer, or null if it doesn't exist.
     * @param name The name of the value.
     *
     * @returns - The value.
     */
    getIntNullable(name: string): number | null {
        return this.state.getIntNullable(name);
    }

    /**
     * Get a value from the state as a float, or null if it doesn't exist.
     * @param name The name of the value.
     *
     * @returns - The value.
     */
    getFloatNullable(name: string): number | null {
        return this.state.getFloatNullable(name);
    }

    /* WATCHERS */

    /**
     * Watches a key for changes.
     * @param key The key to watch.
     * @param converter The converter to use before returning the value.
     *
     * @returns - The observable.
     */
    public watch<T extends NirbyVariableNullable>(
        key: TK,
        converter: Converter<T>,
    ): Observable<T> {
        return this.state$.pipe(
            map((variables) => converter.convert(variables.value[key] ?? null)),
            distinctUntilChanged(),
        );
    }

    /**
     * Watches a key for changes as a string.
     * @param key The key to watch.
     *
     * @returns - The observable.
     */
    public watchString(key: TK): Observable<string> {
        return this.watch(key, STRING_CONVERTER);
    }

    /**
     * Watches a key for changes as a float.
     * @param key The key to watch.
     *
     * @returns - The observable.
     */
    public watchFloat(key: TK): Observable<number> {
        return this.watch(key, FLOAT_CONVERTER);
    }

    /**
     * Watches a key for changes as an integer.
     * @param key The key to watch.
     *
     * @returns - The observable.
     */
    public watchInt(key: TK): Observable<number> {
        return this.watch(key, INT_CONVERTER);
    }

    /**
     * Watches a key for changes as a boolean.
     * @param key The key to watch.
     *
     * @returns - The observable.
     */
    public watchBool(key: TK): Observable<boolean> {
        return this.watch(key, BOOL_CONVERTER);
    }

    /**
     * Sets a value, or multiple values in the state
     * @param name The name of the value.
     * @param value The value to set.
     */
    public set<T extends NirbyVariableNullable | PartialRecord<NirbyVariableNullable>>(name: string, value: T): void {
        if (typeof value === 'object' && value !== null) {
            const mask = this.mask(name);
            Object.entries(value).forEach(([key, value]) => {
                if (value !== undefined) {
                    mask.set(key, value);
                }
            });
        } else {
            this.subject.next(this.state.copyWith(name, value));
        }
    }

    /**
     * Sets many values in the state.
     * @param values The values to set.
     */
    public setMany(values: { [key: string]: NirbyVariable }): void {
        this.subject.next(this.state.copyWithMany(values));
    }

    /* ACTIONS */

    /**
     * Handles an `variable-update` action.
     * @param action The action.
     */
    public handleAction(action: VariableUpdateAction): void {
        const variable = action.options.variable;
        const amount = action.options.amount;
        const oldValue = this.state.value[variable] ?? null;

        let newValue: NirbyVariableNullable;
        switch (action.options.operation) {
            case 'add':
                newValue =
                    FLOAT_CONVERTER.convert(oldValue) +
                    FLOAT_CONVERTER.convert(amount);
                break;
            case 'subtract':
                newValue =
                    FLOAT_CONVERTER.convert(oldValue) -
                    FLOAT_CONVERTER.convert(amount);
                break;
            case 'set':
                newValue = amount;
                break;
            case 'clear':
                newValue = null;
                break;
        }
        Logger.logStyled(
            'MEMORY:SET',
            `${variable} = ${newValue} (old: ${oldValue})`,
        );
        this.state = this.state.copyWith(variable, newValue);
    }

    /**
     * Clears the state.
     * @param newValue The new value to set.
     */
    clear(newValue?: WeakAttributes): void {
        this.subject.next(new WeakTypedState(newValue));
    }

    /**
     * Gets a mask of the state, to be used for setting values, just as {@link NirbyMemory} works, but behind a prefix.
     * @param prefix The prefix.
     *
     * @returns - The mask.
     */
    public mask(prefix: string): NirbyMemoryMask {
        return new NirbyMemoryMask(prefix, this);
    }

    /**
     * Gets the parent of this memory. This is used for nested memories.
     */
    get parent(): null {
        return null;
    }

    /**
     * Gets the keys of the state.
     */
    get keys(): string[] {
        return Object.keys(this.state.value);
    }
}

/**
 * A mask for a {@link NirbyMemory} to use only have access to values with key with a given prefix.
 *
 * @example
 * const memory = new NirbyMemory();
 * const mask = memory.mask('foo');
 * mask.set('bar', 'baz');
 * mask.get('bar'); // => 'baz'
 * memory.get('foo.bar'); // => 'baz'
 * memory.get('foo.baz'); // => null
 */
export class NirbyMemoryMask implements StateManager {
    /**
     * Removes the prefix from the given keys.
     * @param keys The keys.
     * @private
     *
     * @returns - The keys without the prefix.
     */
    private removePrefix(keys: string[]): string[] {
        return keys
            .map((key) => key.match(this.prefixRegex))
            .map((match) => {
                if (!match || match.length < 2) {
                    return null;
                }
                return match[1];
            })
            .filter((key) => key !== null)
            .map((key) => key as string);
    }

    /**
     * Gets a value from the state.
     * @param name The name of the value.
     *
     * @returns - The value.
     */
    get(name: string): NirbyVariableNullable {
        return this.parent.state.value[this.prefixed(name)] ?? null;
    }

    /**
     * Gets the given value from the state without the prefixes.
     * @param value The value.
     * @private
     *
     * @returns - The value without the prefixes.
     */
    private maskStateValue(value: WeakAttributes): WeakAttributes {
        const masked: WeakAttributes = {};

        const keys = this.removePrefix(Object.keys(value));

        let key: string;
        for (key of keys) {
            masked[key] = value[this.prefixed(key)];
        }

        return masked;
    }

    /**
     * Watches the state of the memory behind this mask.
     */
    public get state$(): Observable<WeakTypedState> {
        return this.memory.state$.pipe(
            map((state) => new WeakTypedState(this.maskStateValue(state.value))),
        );
    }

    /**
     * Watches the state of the memory behind this mask.
     */
    public get stateOfLevel$(): Observable<WeakTypedState> {
        return this.state$.pipe(
            pairwise(),
            filter(([prev, curr]) => !prev.equals(curr)),
            map(([, curr]) => curr),
        );
    }

    /**
     * Constructor.
     * @param prefix The prefix.
     * @param memory The parent memory.
     */
    constructor(private prefix: string, private memory: NirbyMemory) {
    }

    /**
     * The RegEx to match the keys with the given prefix.
     * @private
     */
    private get prefixRegex(): RegExp {
        return new RegExp(`^${this.prefix}.(.*)$`);
    }

    /**
     * Gets the current state of the memory.
     */
    public get state(): WeakTypedState {
        return new WeakTypedState(this.maskStateValue(this.memory.state.value));
    }

    /**
     * Gets the current state of the memory.
     */
    public get stateOfLevel(): WeakTypedState {
        return this.state.filterKeys((key) => key.split('.').length === 1);
    }

    /**
     * Adds the prefix to the given key.
     * @param name The name.
     * @private
     *
     * @returns - The prefixed key.
     */
    private prefixed(name: string): string {
        return [this.prefix, name].join('.');
    }

    /**
     * Sets a value in the memory.
     * @param name The name of the value to set.
     * @param value The value to set.
     */
    set<T extends NirbyVariableNullable | PartialRecord<NirbyVariableNullable>>(name: string, value: T) {
        if (typeof value === 'object' && value !== null) {
            const mask = this.memory.mask(this.prefixed(name));
            mask.clear();
            mask.setMany(value);
        } else {
            this.memory.set(this.prefixed(name), value);
        }
    }

    /**
     * Sets many values at once.
     * @param values The values to set.
     */
    setMany(values: PartialRecord<NirbyVariableNullable>): void {
        Object.entries(values).forEach(([key, val]) => {
            if (val !== null && val !== undefined) {
                this.set(key, val);
            }
        });
    }

    /* GETTERS */

    /**
     * Gets a value from the memory as a boolean.
     * @param name The name of the value to get.
     *
     * @returns - The value.
     */
    getBool(name: string): boolean {
        return this.memory.getBool(this.prefixed(name));
    }

    /**
     * Gets a value from the memory as a float.
     * @param name The name of the value to get.
     *
     * @returns - The value.
     */
    getFloat(name: string): number {
        return this.memory.getFloat(this.prefixed(name));
    }

    /**
     * Gets a value from the memory as an integer.
     * @param name The name of the value to get.
     *
     * @returns - The value.
     */
    getInt(name: string): number {
        return this.memory.getInt(this.prefixed(name));
    }

    /**
     * Gets a value from the memory as a string.
     * @param name The name of the value to get.
     *
     * @returns - The value.
     */
    getString(name: string): string {
        return this.memory.getString(this.prefixed(name));
    }

    /* GETTERS NULLABLE */

    /**
     * Gets a value from the memory as a boolean, or null if it doesn't exist.
     * @param name The name of the value to get.
     *
     * @returns - The value.
     */
    getBoolNullable(name: string): boolean | null {
        return this.memory.getBoolNullable(this.prefixed(name));
    }

    /**
     * Gets a value from the memory as a float, or null if it doesn't exist.
     * @param name The name of the value to get.
     *
     * @returns - The value.
     */
    getFloatNullable(name: string): number | null {
        return this.memory.getFloatNullable(this.prefixed(name));
    }

    /**
     * Gets a value from the memory as an integer, or null if it doesn't exist.
     * @param name The name of the value to get.
     *
     * @returns - The value.
     */
    getIntNullable(name: string): number | null {
        return this.memory.getIntNullable(this.prefixed(name));
    }

    /**
     * Gets a value from the memory as a string, or null if it doesn't exist.
     * @param name The name of the value to get.
     *
     * @returns - The value.
     */
    getStringNullable(name: string): string | null {
        return this.memory.getStringNullable(this.prefixed(name));
    }

    /* WATCHERS */

    /**
     * Watches a value in the memory.
     * @param key The name of the value to watch.
     * @param converter The converter to use.
     * @private
     *
     * @returns - The value.
     */
    private watch<T extends NirbyVariableNullable>(
        key: string,
        converter: Converter<T>,
    ): Observable<T> {
        return this.state$.pipe(
            map((variables) => converter.convert(variables.value[key] ?? null)),
            distinctUntilChanged(),
        );
    }

    /**
     * Watches a value in the memory as a string.
     * @param key The name of the value to watch.
     *
     * @returns - The value.
     */
    public watchString(key: string): Observable<string> {
        return this.watch(key, STRING_CONVERTER);
    }

    /**
     * Watches a value in the memory as a float.
     * @param key The name of the value to watch.
     *
     * @returns - The value.
     */
    public watchFloat(key: string): Observable<number> {
        return this.watch(key, FLOAT_CONVERTER);
    }

    /**
     * Watches a value in the memory as an integer.
     * @param key The name of the value to watch.
     *
     * @returns - The value.
     */
    public watchInt(key: string): Observable<number> {
        return this.watch(key, INT_CONVERTER);
    }

    /**
     * Watches a value in the memory as a boolean.
     * @param key The name of the value to watch.
     *
     * @returns - The value.
     */
    public watchBool(key: string): Observable<boolean> {
        return this.watch(key, BOOL_CONVERTER);
    }

    /**
     * Clears the memory.
     */
    clear(): void {
        const value = this.memory.state.value;
        const newValue: WeakAttributes = {};

        let key: string;
        for (key in value) {
            if (!key.startsWith(this.prefix + '.')) {
                newValue[key] = value[key];
            }
        }
        this.memory.clear(newValue);
    }

    /**
     * Creates a mask with the given prefix.
     * @param prefix The prefix to use.
     *
     * @returns - The mask.
     */
    public mask(prefix: string): NirbyMemoryMask {
        return new NirbyMemoryMask(this.prefix + '.' + prefix, this.memory);
    }

    /**
     * Gets the parent prefix.
     */
    get parentPrefix(): string | null {
        const split = this.prefix.split('.');
        if (split.length === 1) {
            return null;
        }
        return split.slice(0, -1).join('.');
    }

    /**
     * Gets the parent memory.
     */
    get parent(): StateManager {
        const parentPrefix = this.parentPrefix;
        return parentPrefix ? this.memory.mask(parentPrefix) : this.memory;
    }

    /**
     * Replaces the variables in the given string with the values in the memory.
     * @param content The content to replace.
     *
     * @returns - The replaced content.
     */
    transform(content: string): string {
        return this.state.transform(content);
    }

    /**
     * Gets the keys of the state.
     */
    get keys(): string[] {
        return this.removePrefix(Object.keys(this.memory.state.value));
    }
}
