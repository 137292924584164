import {AnyBlock, AnyBlockType, NirbyBlock, NirbyPlayerConfig, Vector2d} from '@nirby/models/nirby-player';
import {
    ButtonBlockDrawable,
    IconBlockDrawable,
    ImageBlockDrawable,
    QuestionBlockDrawable,
    RectangleBlockDrawable,
    SliderBlockDrawable,
    TextBlockDrawable,
    VideoBlockDrawable,
} from './drawables';
import {Type} from '@angular/core';
import {deepCopyBlock} from '../utils';
import {Block} from './block';
import {v4} from 'uuid';
import {NirbyContext} from '@nirby/runtimes/context';
import {NirbyBoardStateNode} from '../art-board-item-factory';

type BlockType<T extends AnyBlockType> = Type<Block<NirbyBlock<T>>>;

/**
 * Factory for creating blocks from a block type or description of type {@link AnyBlock}.
 */
export class BlockFactory {
    private static blocks: {
        [T in AnyBlockType]: BlockType<T>;
    } = {
        Question: QuestionBlockDrawable as unknown as BlockType<'Question'>,
        Slider: SliderBlockDrawable as unknown as BlockType<'Slider'>,
        Image: ImageBlockDrawable as unknown as BlockType<'Image'>,
        Text: TextBlockDrawable as unknown as BlockType<'Text'>,
        Rectangle: RectangleBlockDrawable as unknown as BlockType<'Rectangle'>,
        Button: ButtonBlockDrawable as unknown as BlockType<'Button'>,
        Icon: IconBlockDrawable as unknown as BlockType<'Icon'>,
        Video: VideoBlockDrawable as unknown as BlockType<'Video'>,
    };

    /**
     * Creates a new block from the given block options.
     * @param context The Nirby context.
     * @param block The block options.
     * @param editable Whether the block is draggable.
     *
     * @returns - The new block.
     */
    public static fromBlock<T extends AnyBlock = AnyBlock>(
        context: NirbyContext,
        block: T,
        editable: boolean
    ): Block<T> {
        return this.getBlock(context, deepCopyBlock(block), editable);
    }

    /**
     * Creates a new block from the given block options.
     * @param context The Nirby context.
     * @param options The block options.
     * @param editable Whether the block is editable.
     *
     * @returns - The new block.
     */
    public static getBlock<T extends AnyBlock>(
        context: NirbyContext,
        options: T,
        editable: boolean
    ): Block<T> {
        const BlockDrawable = this.blocks[options.type];
        return new BlockDrawable(
            context,
            options,
            editable
        ) as unknown as Block<T>;
    }

    /**
     * Get the default block options for the given block type.
     * @param type The block type.
     * @param config The Nirby player configuration.
     *
     * @returns - The default block options.
     */
    public static getDefaultBlock(
        type: AnyBlockType,
        config: NirbyPlayerConfig
    ): AnyBlock {
        const block = deepCopyBlock(config.defaults.blocks[type]);
        block.hash = v4();
        return block;
    }

    /**
     * Get the default block options for the given block type.
     * @param type The block type.
     * @param config The Nirby player configuration.
     * @param meta The block meta.
     *
     * @returns - The default block options.
     */
    public static getDefaultBlockState<TMeta>(
        type: AnyBlockType,
        config: NirbyPlayerConfig,
        meta: TMeta | null
    ): NirbyBoardStateNode<TMeta, Block<TMeta>> {
        const block = deepCopyBlock(config.defaults.blocks[type]);
        block.hash = v4();
        return {
            children: [],
            id: block.hash,
            properties: {
                ...block,
            },
            type: 'block',
            meta
        };
    }

    /**
     * Get the minimum size of a block.
     * @param type The block type.
     * @param config The Nirby player configuration.
     *
     * @returns - The minimum size of a block.
     */
    public static getMinSize(
        type: AnyBlockType,
        config: NirbyPlayerConfig
    ): Vector2d {
        const BlockDrawable = this.getBlock(
            NirbyContext.mock(),
            this.getDefaultBlock(type, config),
            false
        );
        return {
            x: BlockDrawable.MIN_WIDTH,
            y: BlockDrawable.MIN_HEIGHT,
        };
    }

    /**
     * Get the default block options for the given block type at the given position.
     * @param type The block type.
     * @param area The area of the block.
     * @param config The Nirby player configuration.
     *
     * @returns - The block options at the given position.
     */
    public static generateBlockAtArea(
        type: AnyBlockType,
        area: [Vector2d, Vector2d],
        config: NirbyPlayerConfig
    ): AnyBlock {
        const newBlock: AnyBlock = BlockFactory.getDefaultBlock(type, config);
        const minSize = BlockFactory.getMinSize(type, config);
        let [width, height] = [area[1].x - area[0].x, area[1].y - area[0].y];
        width = Math.max(width, minSize.x);
        height = Math.max(height, minSize.y);
        newBlock.position = [
            { ...area[0] },
            {
                x: area[0].x + width,
                y: area[0].y + height,
            },
        ];
        return newBlock;
    }

    /**
     * Preloads the block assets.
     * @param block The block to preload.
     *
     * @returns - A promise that resolves when the assets are loaded.
     */
    public static preloadBlock<T extends AnyBlock>(block: T): Promise<void> {
        const BlockDrawable = this.getBlock(
            NirbyContext.mock(),
            block,
            false
        );
        return BlockDrawable.preload();
    }

}
