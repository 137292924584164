/**
 * Allows promises to be executed only after the promises on the same channel have executed
 */
export class AsyncChannel {
    protected lastPromise: Promise<unknown> | null = null;

    /**
     * Adds a promise to the given channel. Next calls to waitChannel will wait for this promise to complete
     * @param promise The promise to be added
     * @private
     *
     * @returns - The promise to wait for.
     */
    protected add<T>(promise: () => Promise<T>): Promise<T> {
        let generatedPromise: Promise<T>;
        if (this.lastPromise) {
            generatedPromise = this.lastPromise
                // execute the promise if the previous promise was rejected
                .catch(() => promise())
                .then(() => promise());
        } else {
            generatedPromise = promise();
        }
        this.lastPromise = generatedPromise;
        return generatedPromise;
    }

    public wait<T>(promise: () => Promise<T>): Promise<T>;

    /**
     * @deprecated Use a function that returns a promise instead. This version will not wait for the previous promise
     * to complete
     */
    public wait<T>(promise: Promise<T>): Promise<T>;

    /**
     * Will execute a promise only after all the promises in the given channel have executed.
     *
     * This also adds the given promise to the channel.
     *
     * @param promise Promise to be executed
     */
    public async wait<T>(promise: Promise<T> | (() => Promise<T>)): Promise<T> {
        if (typeof promise === 'function') {
            return this.add(promise);
        } else {
            return this.add(() => promise);
        }
    }
}
