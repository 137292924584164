import {
    NirbyVariable,
    NirbyVariableDeclaration,
    NirbyVariableNullable,
    NirbyVariableType,
    Pair,
    PartialRecord,
    toEntries,
} from '@nirby/runtimes/state';
import {Conditional, evaluateConditional} from '@nirby/conditionals';
import {Logger} from '@nirby/logger';

const VARIABLE_REGEX = /{{ *((@?)[a-zA-Z][a-zA-Z0-9_]*(\.[a-zA-Z][a-zA-Z0-9_]*)*) *(\| *([a-zA-Z]+))? *}}/g;

const PIPES: Record<string, (v: NirbyVariableNullable) => string> = {
    urlEncode: (value: NirbyVariableNullable) => encodeURIComponent(STRING_CONVERTER.convert(value)),
    lowercase: (value: NirbyVariableNullable) => STRING_CONVERTER.convert(value).toLowerCase(),
    uppercase: (value: NirbyVariableNullable) => STRING_CONVERTER.convert(value).toUpperCase(),
    trim: (value: NirbyVariableNullable) => STRING_CONVERTER.convert(value).trim(),
    trimLeft: (value: NirbyVariableNullable) => STRING_CONVERTER.convert(value).trimLeft(),
    trimRight: (value: NirbyVariableNullable) => STRING_CONVERTER.convert(value).trimRight(),
    firstChar: (value: NirbyVariableNullable) => STRING_CONVERTER.convert(value).charAt(0),
    secondChar: (value: NirbyVariableNullable) => STRING_CONVERTER.convert(value).charAt(1),
    lastChar: (value: NirbyVariableNullable) => STRING_CONVERTER.convert(value).charAt(STRING_CONVERTER.convert(value).length - 1),
    firstWord: (value: NirbyVariableNullable) => {
        const str = STRING_CONVERTER.convert(value);
        const words = str.split(' ');
        return words[0] ?? '';
    },
    lastWord: (value: NirbyVariableNullable): string => {
        const words = STRING_CONVERTER.convert(value).split(' ');
        return words[words.length - 1] ?? '';
    },
};

/**
 * Replace all variable references in a string with the current state variables.
 * @example
 * const memory = new NirbyMemory();
 * memory.set('foo', 'bar');
 * memory.set('bar', 'baz');
 * memory.transform('{{foo}} {{bar}}'); // 'bar baz'
 *
 * @param content The content to transform.
 * @param state The state to use.
 *
 * @returns - The transformed string.
 */
function replaceVariables(content: string, state: WeakTypedState): string {
    return content.replace(VARIABLE_REGEX, (match, name, operator, _, __, pipe) => {
        const isOperator = operator === '@';
        const value = state.getString(name);
        const valueExists = state.has(name);
        if (!valueExists) {
            if (isOperator) {
                return '';
            }
            return `{{${name}}}`;
        }

        const pipeFn = PIPES[pipe];
        if (pipeFn) return pipeFn(value);
        return value;
    });
}

/**
 * An object that can transform any value to a given type.
 */
export class Converter<T> {
    /**
     * Constructor.
     * @param fromNumber Function to convert a number to the given type.
     * @param fromString Function to convert a string to the given type.
     * @param fromBoolean Function to convert a boolean to the given type.
     * @param fromUnknown Function to convert an unknown value to the given type.
     */
    constructor(
        private fromNumber: (_: number) => T,
        private fromString: (_: string) => T,
        private fromBoolean: (_: boolean) => T,
        private fromUnknown: (_: NirbyVariableNullable) => T,
    ) {
    }

    /**
     * Convert a value to the given type.
     * @param value Value to convert.
     *
     * @returns - Converted value.
     */
    public convert(value: NirbyVariableNullable): T {
        return this.convertNullable(value) ?? this.fromUnknown(value);
    }

    /**
     * Convert a value to the given type and returns null if the value is from an unknown type.
     * @param value Value to convert.
     *
     * @returns - Converted value or null.
     */
    public convertNullable(value: NirbyVariableNullable): T | null {
        switch (typeof value) {
            case 'boolean':
                return this.fromBoolean(value);
            case 'number':
                return this.fromNumber(value);
            case 'string':
                return this.fromString(value);
            default:
                return null;
        }
    }
}

export const FLOAT_CONVERTER = new Converter<number>(
    (v) => v,
    (v) => {
        const parsed = parseFloat(v);
        return isNaN(parsed) ? 0 : parsed;
    },
    (v) => (v ? 1 : 0),
    () => 0,
);

export const INT_CONVERTER = new Converter<number>(
    (v) => Math.floor(v),
    (v) => Math.floor(FLOAT_CONVERTER.convert(v)),
    (v) => Math.floor(FLOAT_CONVERTER.convert(v)),
    () => 0,
);

export const STRING_CONVERTER = new Converter<string>(
    (v) => v.toString(10),
    (v) => v,
    (v) => v.toString(),
    () => '',
);

export const ANY_CONVERTER = new Converter<NirbyVariableNullable>(
    (v) => v,
    (v) => v,
    (v) => v,
    (v) => v,
);

export const BOOL_CONVERTER = new Converter<boolean>(
    (v) => !!v,
    (v) => v.length > 0,
    (v) => v,
    (v) => v !== null && v !== undefined && v !== false,
);

export type WeakAttributes = {
    [key: string]: NirbyVariableNullable
};

/**
 * A state that can contain any type of data as variables and then use each one as any of the available types.
 */
export class WeakTypedState<
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    T = never
> {
    readonly value: WeakAttributes;

    /**
     * Get the variable type of the given value.
     * @param value The value to get the type of.
     *
     * @returns - The type of the value.
     */
    public static variableTypeOf(
        value: NirbyVariableNullable,
    ): NirbyVariableType {
        if (value === null) {
            return 'null';
        }
        switch (typeof value) {
            case 'boolean':
                return 'boolean';
            case 'number':
                return 'number';
            case 'string':
                return 'string';
            default:
                throw new Error(`Out of range type: ${typeof value}`);
        }
    }

    /**
     * The current structure of the state. All the variable keys and their types.
     *
     * @returns - The current structure of the state.
     */
    public get declaration(): PartialRecord<NirbyVariableDeclaration> {
        const declaration: PartialRecord<NirbyVariableDeclaration> = {};
        const entries = toEntries(this.value);
        let entry: Pair<string, NirbyVariableNullable>;
        for (entry of entries) {
            declaration[entry[0]] = {
                type: WeakTypedState.variableTypeOf(entry[1]),
                initialValue: entry[1],
                source: 'private',
            };
        }
        return declaration;
    }

    /* GETTERS */

    /**
     * Get a value as a boolean
     * @param name The name of the variable to get.
     *
     * @returns - The value of the variable as a boolean.
     */
    public getBool(name: string): boolean {
        const value = this.get<NirbyVariable>(name, false);
        return BOOL_CONVERTER.convert(value);
    }

    /**
     * Get a value as a string
     * @param name The name of the variable to get.
     *
     * @returns - The value of the variable as a string.
     */
    public getString(name: string): string {
        const value = this.get<NirbyVariable>(name, '');
        return STRING_CONVERTER.convert(value);
    }

    /**
     * Get a value as an integer
     * @param name The name of the variable to get.
     *
     * @returns - The value of the variable as an integer.
     */
    public getInt(name: string): number {
        const value = this.get<NirbyVariable | null>(name, 0);
        return INT_CONVERTER.convert(value);
    }

    /**
     * Get a value as a float
     * @param name The name of the variable to get.
     *
     * @returns - The value of the variable as a float.
     */
    public getFloat(name: string): number {
        const value = this.get<NirbyVariable | null>(name, 0);
        return FLOAT_CONVERTER.convert(value);
    }

    /* GETTERS NULL */

    /**
     * Get a value as a boolean or null if it doesn't exist.
     * @param name The name of the variable to get.
     *
     * @returns - The value of the variable as a boolean or null if it doesn't exist.
     */
    public getBoolNullable(name: string): boolean | null {
        const value = this.get<NirbyVariable>(name, false);
        return BOOL_CONVERTER.convertNullable(value);
    }

    /**
     * Get a value as a string or null if it doesn't exist.
     * @param name The name of the variable to get.
     *
     * @returns - The value of the variable as a string or null if it doesn't exist.
     */
    public getStringNullable(name: string): string | null {
        const value = this.get<NirbyVariable>(name, '');
        return STRING_CONVERTER.convertNullable(value);
    }

    /**
     * Get a value as an integer or null if it doesn't exist.
     * @param name The name of the variable to get.
     *
     * @returns - The value of the variable as an integer or null if it doesn't exist.
     */
    public getIntNullable(name: string): number | null {
        const value = this.get<NirbyVariable | null>(name, 0);
        return INT_CONVERTER.convertNullable(value);
    }

    /**
     * Get a value as a float or null if it doesn't exist.
     * @param name The name of the variable to get.
     *
     * @returns - The value of the variable as a float or null if it doesn't exist.
     */
    public getFloatNullable(name: string): number | null {
        const value = this.get<NirbyVariable | null>(name, null);
        return FLOAT_CONVERTER.convertNullable(value);
    }

    /* OPERATORS */

    /**
     * Get a value from the state.
     * @param name The name of the value to get.
     * @param defaultValue The default value to return if the value is not found.
     * @private
     *
     * @returns - The value, or the default value if the value is not found.
     */
    public get<T extends NirbyVariable | null>(
        name: string,
        defaultValue: T,
    ): T {
        return (this.value[name] as unknown as T) ?? defaultValue;
    }

    /**
     * Creates a copy of this state with a new variable added to the state.
     * @param name The name of the variable to add.
     * @param value The value of the variable to add.
     *
     * @returns - A new state with the variable added.
     */
    public copyWith<T extends NirbyVariableNullable>(
        name: string,
        value: T | null,
    ): WeakTypedState {
        const inMemory = this.value[name];

        const sourceType = typeof inMemory;
        const destinyType = typeof value;
        if (
            inMemory !== undefined &&
            destinyType !== null &&
            sourceType !== destinyType
        ) {
            Logger.warnStyled(
                'CONTEXT',
                `Replacing variable "${name}" of type "${sourceType}" to type "${destinyType}"`,
            );
        }

        const newState = {...this.value};
        newState[name] = value;
        return new WeakTypedState(newState);
    }

    /**
     * Creates a copy of this state with many new variables added to the state.
     * @param values The variables to add.
     *
     * @see WeakTypedState.copyWith
     *
     * @returns - A new state with the variables added.
     */
    public copyWithMany(values: {
        [key: string]: NirbyVariable;
    }): WeakTypedState {
        let state: WeakTypedState = this as WeakTypedState;
        for (const [key, value] of Object.entries(values)) {
            state = state.copyWith(key, value);
        }
        return state;
    }

    /**
     * A new state where variables are filtered by key using a predicate.
     * @param predicate The predicate to use to filter the variables.
     *
     * @returns - A new state with the variables filtered.
     */
    public filterKeys(
        predicate: (key: string) => boolean,
    ): WeakTypedState {
        const newState: Record<string, NirbyVariableNullable> = {};
        for (const [key, value] of Object.entries(this.value)) {
            if (value !== undefined && predicate(key)) {
                newState[key] = value;
            }
        }
        return new WeakTypedState(newState);
    }

    /**
     * Constructor.
     * @param value The initial state.
     */
    constructor(value?: WeakAttributes) {
        this.value = value ?? {};
    }

    /**
     * The number of variables in this state.
     */
    public get size(): number {
        return Object.keys(this.value).length;
    }

    /**
     * Checks if another {@link WeakTypedState} is equal to this one checking variable by variable.
     * @param other The other {@link WeakTypedState} to compare.
     *
     * @returns - `true` if the other {@link WeakTypedState} is equal to this one, `false` otherwise.
     */
    public equals(other: WeakTypedState): boolean {
        const allKeys = [
            ...new Set([
                ...Object.keys(this.value),
                ...Object.keys(other.value),
            ]).values(),
        ];
        return allKeys.every((key) => {
            const otherValue = other.get<NirbyVariableNullable>(key, null);
            const thisValue = this.get<NirbyVariableNullable>(key, null);
            return otherValue === thisValue;
        });
    }

    /**
     * Iterates over the variables in this state.
     * @param fn The function to call for each variable.
     */
    forEach(fn: (key: string, value: NirbyVariableNullable) => void): void {
        for (const [key, value] of Object.entries(this.value)) {
            if (value !== undefined) {
                fn(key, value);
            }
        }
    }

    /**
     * Checks if this state has a variable with the given name.
     * @param name The name of the variable to check.
     *
     * @returns - `true` if this state has a variable with the given name, `false` otherwise.
     */
    has(name: string): boolean {
        return Object.keys(this.value).includes(name);
    }

    /**
     * Evaluates a condition string using this state as the context
     * @param condition The condition to evaluate
     *
     * @returns - The result of the evaluation.
     */
    evaluate(condition: Conditional): boolean {
        return evaluateConditional(condition, this.value);
    }

    /**
     * Transforms a string to replace variables
     * @param text The text to transform
     *
     * @returns - The transformed text.
     */
    transform(text: string): string {
        return replaceVariables(text, this);
    }
}
