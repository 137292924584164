import {Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {ContactsService, NgTranslator, NirbyModalService} from '../../services';
import {Logger} from '@nirby/logger';
import {NirbyVariableNullable} from '@nirby/runtimes/state';
import {NirbyContext, WeakAttributes} from '@nirby/runtimes/context';
import {EmbedAction, OpenFormAction, OpenFormSettings, VideoAction} from '@nirby/models/actions';
import {VideoLiteFactoryService} from '@nirby/media/video-factory';

@Component({
    selector: 'nirby-context-modals',
    templateUrl: './context-modals.component.html',
    styleUrls: ['./context-modals.component.css'],
})
/**
 * Component to display modals from the actions emitted by the context
 */
export class ContextModalsComponent {
    @Output() invasiveActionStart = new EventEmitter<OpenFormAction | VideoAction | EmbedAction>();
    @Output() invasiveActionEnd = new EventEmitter<OpenFormAction | VideoAction | EmbedAction>();

    /**
     * The context to display modals from
     * @param value The context to display modals from
     */
    @Input() set context(value: NirbyContext) {
        if (value === this.contextPrivate) {
            return;
        }

        // remove previous listeners
        this.contextPrivate.actionListener.setListener('open-embed', () => {
            return;
        });
        this.contextPrivate.actionListener.setListener(
            'answer-question',
            () => {
                return;
            },
        );
        this.contextPrivate.actionListener.setListener('open-form', () => {
            return;
        });
        this.contextPrivate.actionListener.setListener('video', () => {
            return;
        });

        // set new listeners
        value.actionListener.setListener('open-embed', async (action) => {
            if (action.options && this.lightbox) {
                this.iframeSrc = action.options.src;
                this.invasiveActionStart.emit(action);
                await this.modals.open(this.lightbox, {
                    dismissAll: true,
                    size: 'xl',
                    centered: true,
                    windowClass: 'nbypop-lightbox nbypop-lightbox-contact-form',
                });
                this.invasiveActionEnd.emit(action);
            }
        });

        value.actionListener.setListener('answer-question', async (action) => {
            const {productId} = value;
            if (!productId) {
                return;
            }
            await this.answerQuestion(
                value,
                action.options.questionId,
                action.options.answer,
            );
        });

        value.actionListener.setListener('open-form', async (action) => {
            this.invasiveActionStart.emit(action);
            await this.openForm(value, action.options);
            this.invasiveActionEnd.emit(action);
        });

        value.actionListener.setListener('video', async (action) => {
            if (action.options && this.lightbox) {
                this.iframeSrc = this.videos
                    .getSourceService(action.options.type)
                    .getEmbedUrlFromId(action.options.id);
                this.invasiveActionStart.emit(action);
                await this.modals.open(this.lightbox, {
                    dismissAll: true,
                    size: 'xl',
                    centered: true,
                    windowClass: 'nbypop-lightbox nbypop-lightbox-contact-form',
                });
                this.invasiveActionEnd.emit(action);
            }
        });

        this.contextPrivate = value;
    }

    /**
     * The context to display modals from
     */
    public get context(): NirbyContext {
        return this.contextPrivate;
    }

    public contextPrivate = new NirbyContext(this.translator);

    @ViewChild('lightbox') lightbox?: ElementRef;

    @ViewChild('contactForm') contactForm?: ElementRef;
    public formOptions: OpenFormSettings | null = null;

    public iframeSrc: string | null = null;

    /**
     * Constructor.
     * @param modals The modal service.
     * @param contacts The contact service.
     * @param videos The video service.
     * @param translator The translator service.
     */
    constructor(
        private modals: NirbyModalService,
        private contacts: ContactsService,
        private videos: VideoLiteFactoryService,
        private translator: NgTranslator,
    ) {
    }

    /**
     * Opens a form.
     * @param context The context to open the form in.
     * @param settings The settings to use.
     */
    public async openForm(
        context: NirbyContext,
        settings: OpenFormSettings,
    ): Promise<void> {
        if (!this.contactForm) {
            return;
        }

        this.formOptions = settings;
        await this.modals.open<WeakAttributes>(
            this.contactForm,
            {
                dismissAll: true,
                size: 'sm',
                centered: true,
                windowClass: `nbypop-lightbox nbypop-lightbox-form nbypop-lightbox-form-${settings}`,
            },
        );
    }

    /**
     * Answers a question.
     * @param context The context to answer the question for.
     * @param questionId The question to answer.
     * @param answer The answer to give.
     */
    public async answerQuestion(
        context: NirbyContext,
        questionId: string,
        answer: NirbyVariableNullable,
    ): Promise<void> {
        const productId = context.productId;
        const contact = context.contact;
        if (!productId || !contact) {
            return;
        }
        try {
            await this.contacts.answerQuestion(
                productId.workspaceId,
                contact,
                questionId,
                answer,
            );
        } catch (e) {
            Logger.error('Failed to update contact');
        }
    }

    /**
     * Updates the current contact properties
     * @param context The context
     * @param properties Properties to update
     */
    async updateContact(
        context: NirbyContext,
        properties: Record<string, NirbyVariableNullable>,
    ): Promise<void> {
        const {contact, productId} = this.context;
        if (!contact || !productId) {
            return;
        }
        try {
            const entries = Object.entries(properties);

            const newProperties = entries.reduce<Record<string, NirbyVariableNullable>>((acc, [key, value]) => {
                if (value !== null) {
                    acc[key.replace('.', NirbyContext.PROPERTIES_SPLITTER)] =
                        value;
                }
                return acc;
            }, {});
            await this.contacts.update(
                contact,
                productId.workspaceId,
                newProperties,
            );
            this.modals.dismissAll();
            this.formOptions = null;
        } catch (e) {
            Logger.error('Error updating contact');
        }
    }
}
