import { z } from 'zod';
import { VideoBlock, VIDEO_BLOCK_MIGRATOR } from './video.block.model';
import { TEXT_BLOCK_MIGRATOR, TextBlock } from './text.block.model';
import {
    RectangleBlock,
    RECTANGLE_BLOCK_MIGRATOR,
} from './rectangle.block.model';
import { IMAGE_BLOCK_MIGRATOR, ImageBlock } from './image.block.model';
import { ICON_BLOCK_MIGRATOR, IconBlock } from './icon.block.model';
import { BUTTON_BLOCK_MIGRATOR, ButtonBlock } from './button.block.model';
import {
    QUESTION_BLOCK_MIGRATOR,
    SLIDER_BLOCK_MIGRATOR,
    WidgetQuestionBlock,
    WidgetSliderBlock,
} from './widgets';
import { Migrator } from '@nirby/store/migrator';
import { VECTOR_2D_SCHEMA } from '../vector2';
import { ANY_CARD_ACTION_SCHEMA } from '@nirby/models/actions';

export type AnyBlock =
    | TextBlock
    | ImageBlock
    | RectangleBlock
    | ButtonBlock
    | IconBlock
    | VideoBlock
    | WidgetSliderBlock
    | WidgetQuestionBlock;

const MIGRATOR_BY_TYPE: {
    [BlockType in AnyBlock['type']]: Migrator<
        PickBlockOfType<AnyBlock, BlockType>
    >;
} = {
    Button: BUTTON_BLOCK_MIGRATOR,
    Icon: ICON_BLOCK_MIGRATOR,
    Question: QUESTION_BLOCK_MIGRATOR,
    Rectangle: RECTANGLE_BLOCK_MIGRATOR,
    Slider: SLIDER_BLOCK_MIGRATOR,
    Video: VIDEO_BLOCK_MIGRATOR,
    Text: TEXT_BLOCK_MIGRATOR,
    Image: IMAGE_BLOCK_MIGRATOR,
};

/**
 * Returns the migrator for a block type
 * @param type The type of the block
 *
 * @returns - The migrator for the block
 */
function blockMigratorFactory<T extends AnyBlock>(
    type: string
): Migrator<T> | null {
    const migrator = MIGRATOR_BY_TYPE[type as AnyBlock['type']];
    if (migrator === undefined) {
        return null;
    }
    return migrator as Migrator<T>;
}

/**
 * hash: '',
 *         position: [
 *             {x: 0, y: 0},
 *             {x: 128, y: 128},
 *         ],
 *         rotation: 0,
 *         content: {
 *             color: '#3b14d3',
 *             emoji: '😍',
 *             title: 'Insert text here...',
 *         },
 *         style: {},
 *         actions: {
 *             click: [],
 *         },
 *         type: 'Slider',
 */

export const ANY_BLOCK_SCHEMA = z
    .object({
        hash: z.string(),
        type: z.string(),
        position: z.tuple([VECTOR_2D_SCHEMA, VECTOR_2D_SCHEMA]),
        rotation: z.number(),
        content: z.unknown(),
        style: z.unknown(),
        actions: z
            .object({
                click: z.array(ANY_CARD_ACTION_SCHEMA).default([]),
            })
            .default({
                click: [],
            }),
    })
    .transform((checked) => {
        const migrator = blockMigratorFactory(checked.type);
        if (migrator === null) {
            throw new Error(`Invalid block type ${checked.type}`);
        }
        return migrator.migrate(checked).migrated;
    });

export type AnyBlockType = AnyBlock['type'];

export type PickBlockOfType<
    TB extends AnyBlock,
    T extends AnyBlockType
> = TB extends { type: T } ? TB : never;
export type NirbyBlock<T extends AnyBlockType = AnyBlockType> = PickBlockOfType<
    AnyBlock,
    T
>;

export * from './block.model';

export * from './button.block.model';
export * from './icon.block.model';
export * from './image.block.model';
export * from './rectangle.block.model';
export * from './text.block.model';
export * from './video.block.model';
export * from './widgets';
